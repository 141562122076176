import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
  GridItem,
  GridContextProvider,
  GridDropZone,
  swap,
} from 'react-grid-dnd';
import { useBreakpoint } from '../tools';
import { Collapse } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getProductsDraggableHeight } from './productosHelper';

const DraggablePropTypes = {
  children: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  handleChangeDragPosition: PropTypes.func.isRequired,
  boxesPerRow: PropTypes.number,
  rowHeight: PropTypes.number,
};

const Draggable = ({
  children,
  items,
  handleChangeDragPosition,
  boxesPerRow = 3,
  rowHeight = 320,
  ...props
}) => {
  const [openAlert, setOpen] = useState(true);
  const breakpoint = useBreakpoint();

  const onChange = (_, sourceIndex, targetIndex) => {
    const nextState = swap(items, sourceIndex, targetIndex);
    handleChangeDragPosition(nextState);
  };

  return (
    <GridContextProvider onChange={onChange}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Collapse in={openAlert}>
            <Alert
              severity="info"
              action={(
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )}
            >
              Arrastrá y soltá los cards para ordenar los productos
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <GridDropZone
            id="draggable"
            boxesPerRow={breakpoint.value}
            rowHeight={rowHeight}
            style={{ height: getProductsDraggableHeight(items.length, rowHeight, boxesPerRow) }}
            {...props}
          >
            {items?.map(item => (
              <GridItem key={item}>
                {children({ item })}
              </GridItem>
            ))}
          </GridDropZone>
        </Grid>
      </Grid>
    </GridContextProvider>
  );
};

Draggable.propTypes = DraggablePropTypes;

export default Draggable;
