import React from 'react';
import PropTypes from 'prop-types';
import EditableZone from '../shared/Components/Editable';
import RecetaForm from './RecetaForm';
import { updateReceta } from './Receta/recetaHelper';

const UpdateRecetaPropTypes = {
  receta: PropTypes.object.isRequired,
  reloadPage: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const UpdateReceta = ({ receta, reloadPage, children }) => {
  const handleUpdateReceta = async (apiFormValues) => {
    try {
      const response = await updateReceta(apiFormValues, receta.fid);
      reloadPage();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <EditableZone
      Editable={(
        <RecetaForm receta={receta} handleAddUpdate={handleUpdateReceta} />
      )}
    >
      {children}
    </EditableZone>
  );
};

UpdateReceta.propTypes = UpdateRecetaPropTypes;

export default UpdateReceta;
