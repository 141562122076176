import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import DangerFormAlert from '../shared/Components/DangerFormAlert';
import FieldText from '../shared/Components/Form/FieldText';
import FieldTextImagePreview from '../shared/Components/Form/FieldTextImagePreview';
import FormGridItem from '../shared/Components/Form/FormGridItem';
import FormGridContainer from '../shared/Components/Form/FormGridContainer';
import FormAlert from '../shared/Components/Form/FormAlert';
import FieldRichText from '../shared/Components/Form/FieldRichText';
import { isEmpty, isNotEmpty } from '../tools';
import { buildFieldNameFieldArray } from '../shared/Components/Form/formTools';
import FormTitle from '../shared/Components/Form/FormTitle';
import FieldArrayPairButtons from '../shared/Components/Form/FieldArrayPairButtons';
import { formDefaultValues, formValuesToApi, getErrorFieldArrayByName } from './productosFormHelper';
import { Divider } from '@material-ui/core';
import { useNeoforniForm } from '../shared/Components/Form/useNeoforniForm';

const ProductosForm = ({ handleAddUpdate, product }) => {
  const isAddNewItem = isEmpty(product);
  const IMAGES_BIG_FIELD_ARRAY_NAME = 'images_big';
  const ACCESORIES_FIELD_ARRAY_NAME = 'accesories';

  const defaultValues = isAddNewItem ? formDefaultValues() : formDefaultValues(product);

  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo requerido'),
    subtitle: yup.string().required('Campo requerido'),
    description: yup
      .string()
      .min(10, 'El campo debe tener como mínimo 50 caracteres')
      .required('Campo requerido'),
    features: yup
      .string()
      .min(10, 'El campo debe tener como mínimo 50 caracteres')
      .required('Campo requerido'),
    technical_image: yup.string().required('Campo requerido'),
    video: yup.string().required('Campo requerido'),
    images_big: yup
      .array()
      .of(
        yup.object().shape({
          image: yup.string().required('Campo requerido'),
        }),
      ),
    accesories: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('Campo requerido'),
          description: yup.string().required('Campo requerido'),
          image: yup.string().required('Campo requerido'),
        }),
      ),
  });

  const form = useNeoforniForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (formValues) => {
    try {
      const newFormValues = formValuesToApi(formValues);
      const response = await handleAddUpdate(newFormValues);
      return response;
    } catch (error) {
      form.setErrorSubmit(error);
      console.error(error);
    };
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        name="productos_form"
        id="productos_form"
        noValidate
      >
        <FormGridContainer>
          <FieldText name="name" label="Titulo" />
          <FieldText name="subtitle" label="Subtitulo" />
          <FieldRichText name="description" label="Descripción" />
          <FieldRichText name="features" label="Características" />
          <FieldTextImagePreview name="technical_image" label="Imagen técnica" />
          <FieldText name="video" label="Video" />
          <FieldArrayImages fieldArrayName={IMAGES_BIG_FIELD_ARRAY_NAME} title="Imagenes del producto" />
          <FieldArrayAccesories fieldArrayName={ACCESORIES_FIELD_ARRAY_NAME} />
          <FormGridItem>
            <DangerFormAlert
              isSubmitting={form.isSubmitting}
              isAddNewItem={isAddNewItem}
            />
          </FormGridItem>
          <FormAlert isSubmitting={form.isSubmitting}>{form.errorSubmit?.message}</FormAlert>
        </FormGridContainer>
      </form>
    </FormProvider>
  );
};

const FieldArrayImages = ({ fieldArrayName, title }) => {
  const form = useFormContext();
  const { fields = [], append, remove } = useFieldArray({
    name: fieldArrayName,
    control: form.control,
  });

  const handleAppendImage = () => append({ image: '' });
  const handleRemoveImage = (position) => remove(position);

  return (
    <FormGridItem container xs={12} spacing={4}>
      {isNotEmpty(fields) && <FormTitle title={title} gutterBottom />}
      {fields.map((image, idx) => {
        const error = getErrorFieldArrayByName({ form, fieldArrayName, fieldName: 'image', idx });
        return (
          <Fragment key={image.id}>
            <FieldTextImagePreview
              name={buildFieldNameFieldArray(fieldArrayName, 'image', idx)}
              label="Imagen"
              error={!!error}
              helperText={error}
              gridProps={{ xs: 10 }}
            />
            <FieldArrayPairButtons
              key={image.id}
              xs={2}
              buttonAddProps={{
                onClick: handleAppendImage,
              }}
              buttonRemoveProps={{
                onClick: () => handleRemoveImage(idx),
              }}
            />
          </Fragment>
        );
      })}
    </FormGridItem>
  );
};

const FieldArrayAccesories = ({ fieldArrayName }) => {
  const form = useFormContext();
  const { fields = [], append, remove } = useFieldArray({
    name: fieldArrayName,
    control: form.control,
    defaultValue: '',
  });

  const handleAppendItem = () => append({ name: '', description: '', image: '' });
  const handleRemoveItem = (position) => remove(position);
  const getErrors = (idx) => {
    const errorName = getErrorFieldArrayByName({ form, fieldArrayName, fieldName: 'name', idx });
    const errorImage = getErrorFieldArrayByName({ form, fieldArrayName, fieldName: 'image', idx });
    const errorDescription = getErrorFieldArrayByName({ form, fieldArrayName, fieldName: 'description', idx });

    return [errorName, errorDescription, errorImage];
  };

  return (
    <FormGridItem container xs={12} spacing={4}>
      {isNotEmpty(fields) && <FormTitle title="Accesorios" gutterBottom />}
      {fields.map((accesorie, idx) => {
        const [errorName, errorDescription, errorImage] = getErrors(idx);
        return (
          <Fragment key={accesorie.id}>
            <FormGridItem>
              <Divider style={{ height: '5px' }} />
            </FormGridItem>
            <FormGridItem container spacing={2} xs={10}>
              <FieldText
                name={buildFieldNameFieldArray(fieldArrayName, 'name', idx)}
                label="Nombre"
                error={!!errorName}
                helperText={errorName}
              />
              <FieldTextImagePreview
                name={buildFieldNameFieldArray(fieldArrayName, 'image', idx)}
                label="Imagen"
                error={!!errorImage}
                helperText={errorImage}
              />
              <FieldRichText
                name={buildFieldNameFieldArray(fieldArrayName, 'description', idx)}
                label="Descripción"
                helperText={errorDescription}
              />
            </FormGridItem>
            <FieldArrayPairButtons
              key={accesorie.id}
              xs={2}
              buttonAddProps={{
                onClick: handleAppendItem,
              }}
              buttonRemoveProps={{
                onClick: () => handleRemoveItem(idx),
              }}
            />
          </Fragment>
        );
      })}
    </FormGridItem>
  );
};

FieldArrayImages.propTypes = {
  fieldArrayName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

FieldArrayAccesories.propTypes = {
  fieldArrayName: PropTypes.string.isRequired,
};

ProductosForm.propTypes = {
  handleAddUpdate: PropTypes.func.isRequired,
  product: PropTypes.object,
};

export default ProductosForm;
