import PropTypes from 'prop-types';

const ShowMarkUpText = ({ text, ...props }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: text }} {...props} />
  );
};

ShowMarkUpText.propTypes = {
  text: PropTypes.string,
};

export default ShowMarkUpText;
