import _ from 'lodash';
import { getCollectionData, updateItem, addItem } from '../firebase';
import { convertReadableToKey, slugify, isFloat } from '../tools';

const addProduct = async (product) => {
  const products = await getCollectionData('productos');
  const newProduct = {
    ...product,
    position: products.length,
  };
  return addItem(newProduct, 'productos');
};

const updateProduct = async (product, id) => {
  return updateItem(product, id, 'productos');
};

const getProductFolderPath = (productName) => {
  if (productName) {
    return `productos/${convertReadableToKey(slugify(productName))}`.split('-').join('_');
  }
};

const getProductPath = (item) => {
  return `productos/${convertReadableToKey(item.name)}/${item.fid}`;
};

const sortProducts = (products) => {
  const newProductsShallow = [...products];
  newProductsShallow.sort((a, b) => {
    if (a.position < b.position) {
      return -1;
    } else if (a.position > b.position) {
      return 1;
    }

    return 0;
  });

  return newProductsShallow;
};

const addProductInfoToEmailFields = (emailFields, productName) => ({
  ...emailFields,
  product_requested: productName,
});

const getProductsDraggableHeight = (productsAmount, rowHeight, productsPerRow) => {
  const numberOfLines = productsAmount / productsPerRow;
  if (isFloat(numberOfLines)) {
    return (_.floor(numberOfLines) + 1) * rowHeight;
  } else {
    return numberOfLines * rowHeight;
  }
};

export {
  addProduct,
  updateProduct,
  getProductFolderPath,
  getProductPath,
  addProductInfoToEmailFields,
  sortProducts,
  getProductsDraggableHeight,
};
