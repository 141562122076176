import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import pageHeader from '../../assets/page-header-bg.jpeg';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '180px',
    padding: '40px 0',
    marginBottom: '20px',
    backgroundImage: `url(${pageHeader})`,
    [theme.breakpoints.down('md')]: {
      height: '90px',
    },
    [theme.breakpoints.up('md')]: {
      height: '180px',
    },
  },
}));

const NeoforniHeader = ({ title, subtitle, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h4" gutterBottom {...props}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h6" gutterBottom {...props} color="secondary">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

NeoforniHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default NeoforniHeader;
