import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { DialogContent, Divider, makeStyles, useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
    },
    paper: {
      maxWidth: '100%',
    },
    dialogContent: {
      width: '100%',
      height: '100%',
    },
    closeIcon: {
      position: 'absolute',
      right: '10px',
    },
  };
});

const PaperComponent = (props) => {
  return (
    <Paper {...props} />
  );
};

// eslint-disable-next-line react/display-name
const NeoforniModal = forwardRef(({
  maxWidth = 'lg',
  labelConfirm = 'Ok',
  labelCancel = 'Cancel',
  title,
  enableButtons,
  disablePadding,
  noWrapChildren,
  buttonConfirmProps,
  buttonCancelProps,
  classes = {
    root: {},
    dialogContent: {},
    paper: {},
  },
  children,
  ...props
}, ref) => {
  const theme = useTheme();
  const myClasses = useStyles();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleModal = () => {
    setOpen(!open);
  };

  useImperativeHandle(ref, () => {
    return {
      handleToggleModal,
    };
  });

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const {
        current: descriptionElement,
      } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="draggable-dialog-description"
        PaperComponent={PaperComponent}
        style={{ zIndex: theme.zIndex.appBar + 1 }}
        maxWidth={maxWidth}
        {...props}
      >
        {title && (
          <>
            <DialogTitle
              id="draggable-dialog-title"
              classes={{ root: clsx(myClasses.root, classes.root) }}
              disableTypography
            >
              <Typography variant="h5">{title}</Typography>
              <IconButton onClick={handleClose} className={myClasses.closeIcon}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider />
          </>
        )}
        {noWrapChildren ? (
          children
        ) : (
          <DialogContent
            classes={{
              root: clsx(myClasses.dialogContent, classes.dialogContent),
            }}
            style={{
              padding: disablePadding ? 0 : '20px',
            }}
          >
            {children}
          </DialogContent>
        )}
        {enableButtons && (
          <DialogActions>
            <Button
              onClick={handleClose}
              color="default"
              {...buttonCancelProps}
            >
              {buttonCancelProps?.children || labelCancel}
            </Button>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="outlined"
              {...buttonConfirmProps}
            >
              {buttonConfirmProps?.children || labelConfirm}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
});

NeoforniModal.propTypes = {
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  enableButtons: PropTypes.bool,
  disablePadding: PropTypes.bool,
  noWrapChildren: PropTypes.bool,
  labelConfirm: PropTypes.string,
  labelCancel: PropTypes.string,
  buttonConfirmProps: PropTypes.object,
  buttonCancelProps: PropTypes.object,
  children: PropTypes.any,
  classes: PropTypes.shape({
    root: PropTypes.object,
    dialogContent: PropTypes.object,
    paper: PropTypes.object,
  }),
};

export default NeoforniModal;
