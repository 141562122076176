import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';

const useStyles = props => makeStyles(() => ({
  container: {
    display: props.center && 'flex',
    alignItems: props.center && 'center',
    justifyContent: props.center && 'center',
  },
}));

const NeoforniContainer = ({ center, classes = { container: {} }, ...props }) => {
  const myClasses = useStyles({ center })();
  return (
    <Container
      maxWidth={false}
      classes={{
        root: clsx(myClasses.container, classes.container),
      }}
      {...props}
    />
  );
};

NeoforniContainer.propTypes = {
  center: PropTypes.bool,
  classes: PropTypes.object,
};

export default NeoforniContainer;
