import React, { useState } from 'react';
import { shuffle } from 'lodash';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PropTypes from 'prop-types';
import { convertReadableToKey, historyPushSlugify, usePromiseToState } from '../../tools';
import { getCollectionData } from '../../firebase';
import NeoforniMultipleCarousel from '../../shared/Components/NeoforniMultipleCarousel';
import LoadingErrorWrapper from '../../shared/Containers/LoadingErrorWrapper';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const getRecetaPath = (receta) => {
  return `recetas/${convertReadableToKey(receta.name)}/${receta.fid}`;
};

const RecetaVerMas = ({ item }) => {
  const classes = useStyles();
  const history = useHistory();
  const [elevation, setElevation] = useState(0);

  return (
    <Card
      elevation={elevation}
      raised={!!elevation}
      onMouseEnter={() => setElevation(10)}
      onMouseLeave={() => setElevation(0)}
      classes={{ root: classes.root }}
    >
      <Box style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover' }} height="230px" width="100%" />
      <Box
        height="70px"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        mt={1}
      >
        <Typography variant="h6">{item.name_short || item.name}</Typography>
        <Button
          color="secondary"
          variant="text"
          href={getRecetaPath(item)}
          onClick={(e) => {
            e.preventDefault();
            history.push(getRecetaPath(item));
          }}
        >
          Ver mas
        </Button>
      </Box>
    </Card>
  );
};

const RecetasCarousel = () => {
  const [recetasState] = usePromiseToState({
    promise: () => getCollectionData('recetas'),
  });
  const history = useHistory();

  const handleClickSlideItem = (e, item) => {
    historyPushSlugify(getRecetaPath(item), history);
  };

  return (
    <LoadingErrorWrapper spinner {...recetasState}>
      <Grid container spacing={4}>
        <Grid item container xs={12} justify="center" alignItems="center">
          <NeoforniMultipleCarousel
            items={shuffle(recetasState.data)}
            CustomItem={RecetaVerMas}
            onClickSlideItem={handleClickSlideItem}
          />
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Button
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              history.push('/recetas');
            }}
            endIcon={<ArrowForwardIcon />}
            href="/recetas"
          >
            Ver todas las recetas
          </Button>
        </Grid>
      </Grid>
    </LoadingErrorWrapper>
  );
};

RecetaVerMas.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

export default RecetasCarousel;
