import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { usePromiseToState } from '../tools';
import { getCollectionData } from '../firebase';
import ViewNosotros from './ViewNosotros';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';
import UpdateNosotros from './UpdateNosotros';

const ProductOffer = ({ title, description, Icon }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Box mb={2}><Icon /></Box>
      <Box mb={2}>{title}</Box>
      <Box
        overflow="hidden"
        textAlign="center"
        width="70%"
      >
        <Typography gutterBottom variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const Nosotros = () => {
  const [nosotrosState,, reloadPage] = usePromiseToState({
    promise: () => getCollectionData('nosotros'),
  });
  const [nosotros] = nosotrosState.data || {};

  return (
    <NeoforniContainer maxWidth="lg" center>
      <LoadingErrorWrapper {...nosotrosState}>
        <UpdateNosotros nosotros={nosotros} reloadPage={reloadPage}>
          <ViewNosotros nosotros={nosotros} />
        </UpdateNosotros>
      </LoadingErrorWrapper>
    </NeoforniContainer>
  );
};

ProductOffer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.any,
};

Nosotros.propTypes = {
};

export default Nosotros;
