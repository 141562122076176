import PropTypes from 'prop-types';
import * as yup from 'yup';
import firebase from 'firebase';
import DangerFormAlert from '../shared/Components/DangerFormAlert';
import FormGridContainer from '../shared/Components/Form/FormGridContainer';
import { FormProvider } from 'react-hook-form';
import FieldText from '../shared/Components/Form/FieldText';
import FieldTextImagePreview from '../shared/Components/Form/FieldTextImagePreview';
import FormGridItem from '../shared/Components/Form/FormGridItem';
import { isEmpty } from '../tools';
import FormAlert from '../shared/Components/Form/FormAlert';
import FieldRichText from '../shared/Components/Form/FieldRichText';
import { useNeoforniForm } from '../shared/Components/Form/useNeoforniForm';
import { yupResolver } from '@hookform/resolvers';
import FieldSelect from '../shared/Components/Form/FieldSelect';

const recetaTypes = [
  { value: 'salada', label: 'Salada' },
  { value: 'dulce', label: 'Dulce' },
];

const RecetaForm = ({ receta, handleAddUpdate }) => {
  const isAddNewItem = isEmpty(receta);
  const defaultValues = isAddNewItem ? {
    name: '',
    description: '',
    image: '',
    type: 'salada',
    author: '',
  } : {
    name: receta.name,
    description: receta.description,
    image: receta.image,
    author: receta.author,
    type: receta.type,
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Campo requerido')
      .max(30, 'El campo puede tener como máximo 30 caracteres'),
    description: yup
      .string()
      .required('Campo requerido'),
    image: yup
      .string()
      .required('Campo requerido'),
    author: yup
      .string()
      .required('Campo requerido')
      .max(20, 'El campo puede tener como máximo 20 caracteres'),
    type: yup
      .string()
      .oneOf(['dulce', 'salada'], "El campo debe ser 'dulce' o 'salada'")
      .required('Campo requerido'),
  });

  const form = useNeoforniForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (values) => {
    // eslint-disable-next-line camelcase
    const newValues = {
      ...values,
      creation_date: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    try {
      const response = await handleAddUpdate(newValues);
      return response;
    } catch (error) {
      form.setErrorSubmit(error);
      console.error(error);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        name="receta_form"
        id="receta_form"
        noValidate
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormGridContainer>
          <FieldText
            name="name"
            label="Nombre"
          />
          <FieldRichText
            name="description"
            label="Descripción"
          />
          <FieldTextImagePreview
            name="image"
            label="Imagen"
          />
          <FieldText
            name="author"
            label="Autor"
          />
          <FieldSelect
            name="type"
            label="Tipo"
            variant="outlined"
            options={recetaTypes}
            disabled={!isAddNewItem}
          />
          <FormGridItem>
            <DangerFormAlert isSubmitting={form.isSubmitting} isAddNewItem={isAddNewItem} />
          </FormGridItem>
          <FormAlert isSubmitting={form.isSubmitting}>{form.errorSubmit?.message}</FormAlert>
        </FormGridContainer>
      </form>
    </FormProvider>
  );
};

RecetaForm.propTypes = {
  receta: PropTypes.object,
  handleAddUpdate: PropTypes.func.isRequired,
};

export default RecetaForm;
