import { useRef } from 'react';
import PropTypes from 'prop-types';
import TipsForm from './TipsForm';
import { updateTip } from './tipsHelper';
import EditableZone from '../shared/Components/Editable';

const UpdateTipPropTypes = {
  tip: PropTypes.object.isRequired,
  reloadPage: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const UpdateTip = ({ tip, reloadPage, children }) => {
  const modalRef = useRef();
  const handleUpdate = async (formValues) => {
    try {
      const newTip = {
        ...tip,
        ...formValues,
      };
      const response = await updateTip(newTip, tip.fid);
      reloadPage();
      modalRef.current.handleToggleModal();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <EditableZone
      Editable={<TipsForm tip={tip} handleAddUpdate={handleUpdate} />}
    >
      {children}
    </EditableZone>
  );
};

UpdateTip.propTypes = UpdateTipPropTypes;

export default UpdateTip;
