/* eslint-disable array-callback-return */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BreadcrumbsMUI from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useBreadcrumb } from '../../tools';
import { LayoutContext } from '../../Layout';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const NeoforniBreadcrumbs = (props) => {
  const ctxLayout = useContext(LayoutContext);
  const items = useBreadcrumb(ctxLayout.sections);
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      <BreadcrumbsMUI
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {items?.map((item, idx) => {
          if (!item.label) return null;
          return (
            (items.length - 1) !== idx ? (
              <Link key={item.label} color="inherit" href={item.href}>
                {item.label}
              </Link>
            ) : (
              <Typography key={item.label} color="textPrimary">{item.label}</Typography>
            )
          );
        })}
      </BreadcrumbsMUI>
    </div>
  );
};

export default NeoforniBreadcrumbs;
