import PropTypes from 'prop-types';
import { updateItems } from '../firebase';
import { useBreakpoint } from '../tools/index';
import EditableZone from '../shared/Components/Editable';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import VideosForm from './VideosForm';

const UpdateVideosPropTypes = {
  children: PropTypes.node.isRequired,
  videos: PropTypes.array.isRequired,
  reloadPage: PropTypes.func.isRequired,
};

const UpdateVideos = ({ videos, reloadPage, children }) => {
  const { value: breakpointValue } = useBreakpoint();

  const handleUpdateVideos = async (apiFormValues) => {
    try {
      const response = await updateItems(apiFormValues, 'videos');
      reloadPage();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <NeoforniContainer maxWidth={breakpointValue < 2 ? 'sm' : 'md'}>
      <EditableZone
        Editable={(
          <VideosForm
            videos={videos}
            handleAddUpdate={handleUpdateVideos}
          />
        )}
      >
        {children}
      </EditableZone>
    </NeoforniContainer>
  );
};

UpdateVideos.propTypes = UpdateVideosPropTypes;

export default UpdateVideos;
