/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Card, Grid, makeStyles, Typography } from '@material-ui/core';
import { useBreakpoint } from '../../tools';

const useStyles = makeStyles((theme) => ({
  carousel: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '1000px',
    },
  },
  slideCard: {
    paddingBottom: '10px',
  },
  caption: {
    fontSize: '14px',
    padding: '10px',
  },
}));

const SlideItem = ({ item, includeCaption, onClickSlideItem }) => {
  const classes = useStyles();
  const [elevation, setElevation] = useState(0);

  const handleHoverCard = (elev) => {
    setElevation(elev);
  };

  return (
    <Card
      raised={!!elevation}
      classes={{ root: classes.slideCard }}
      onMouseLeave={() => handleHoverCard(0)}
      onMouseEnter={() => handleHoverCard(10)}
      elevation={elevation}
      onClick={onClickSlideItem && ((e) => onClickSlideItem(e, item))}
    >
      <img src={item.image} alt={item.name} width="300px" height="300px" /><br/>
      {includeCaption ? (
        <Typography classes={{ root: classes.caption }} variant="caption">
          {item.name}
        </Typography>
      ) : (
        <div style={{ display: 'none' }}>{item.name}</div>
      )}
    </Card>
  );
};

const Slide = ({ item, includeCaption, onClickSlideItem, CustomItem }) => {
  const totalItems = item.images.length;

  return (
    <Grid container spacing={2}>
      {item.images.map((image) => {
        if (CustomItem) {
          return (
            <Grid
              key={JSON.stringify(image)}
              item
              container
              justify="center"
              alignItems="center"
              xs={12 / totalItems}
            >
              <CustomItem item={image} onClick={onClickSlideItem} />
            </Grid>
          );
        }
        return (
          <Grid
            key={JSON.stringify(image)}
            item
            container
            justify="center"
            alignItems="center"
            xs={12 / totalItems}
          >
            <SlideItem
              item={image}
              includeCaption={includeCaption}
              onClickSlideItem={onClickSlideItem}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const NeoforniMultipleCarousel = ({
  items = [],
  includeCaption,
  onClickSlideItem,
  CustomItem,
}) => {
  const classes = useStyles();
  const [newItems, setNewItems] = useState([]);
  const [amount, setAmount] = useState(3);
  const showIndicators = items.length > amount;
  const areNextAndPreviousButtonVisible = !showIndicators;
  const { breakpoint } = useBreakpoint();

  if (!items || items.length <= 0) return null;

  useEffect(() => {
    const aux = [];
    for (let i = 0; i < items.length; i = i + amount) {
      const images = [];
      Array.from({ length: amount }, (v, idx) => idx).forEach((plus) => {
        if (items[i + plus]) images.push(items[i + plus]);
      });
      aux.push(Object.assign({ images: images }));
    }
    setNewItems(aux);
  }, [items, amount]);

  useEffect(() => {
    switch (breakpoint) {
      case 'xl':
        setAmount(4);
        break;
      case 'lg':
        setAmount(4);
        break;
      case 'md':
        setAmount(3);
        break;
      case 'sm':
        setAmount(2);
        break;
      case 'xs':
        setAmount(1);
        break;
    }
  }, [breakpoint]);

  return (
    <Carousel
      className={classes.carousel}
      autoPlay={false}
      animation="fade"
      timeout={500}
      navButtonsAlwaysVisible={false}
      navButtonsAlwaysInvisible={areNextAndPreviousButtonVisible}
      indicators={showIndicators}
      cycleNavigation
    >
      {newItems.map((item, idx) => {
        return (
          <Slide
            key={JSON.stringify(item)}
            item={item}
            includeCaption={includeCaption}
            onClickSlideItem={onClickSlideItem}
            CustomItem={CustomItem}
          />
        );
      })}
    </Carousel>
  );
};

export default NeoforniMultipleCarousel;
