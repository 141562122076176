import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import ShowMarkUpText from '../shared/Components/ShowMarkUpText';
import YoutubeEmbed from '../shared/Components/YoutubeEmbed';

const CursoTitleDescriptionPropTypes = {
  tallerCurso: PropTypes.object.isRequired,
};

const CursoTitleDescription = ({ tallerCurso }) => {
  const {
    name, description, image, video,
  } = tallerCurso;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        {image ? (
          <Box
            width="100%"
            height="420px"
            style={{
              backgroundImage: `url('${image}')`,
              backgroundSize: 'cover',
            }}
            role="image"
          />
        ) : (
          <YoutubeEmbed src={video} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{`Curso: ${name}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ShowMarkUpText
              text={description}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CursoTitleDescription.propTypes = CursoTitleDescriptionPropTypes;

export default CursoTitleDescription;
