import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import FieldText from '../shared/Components/Form/FieldText';
import DangerFormAlert from '../shared/Components/DangerFormAlert';
import FormGridContainer from '../shared/Components/Form/FormGridContainer';
import FormAlert from '../shared/Components/Form/FormAlert';
import { isEmpty } from '../tools';
import FormGridItem from '../shared/Components/Form/FormGridItem';
import { useNeoforniForm } from '../shared/Components/Form/useNeoforniForm';
import { yupResolver } from '@hookform/resolvers';

const TipsForm = ({ tip, handleAddUpdate }) => {
  const isAddNewItem = isEmpty(tip);
  const defaultValues = isAddNewItem ? {
    title: '',
    description: '',
  } : {
    title: tip.title,
    description: tip.description,
  };

  const validationSchema = yup.object({
    title: yup
      .string()
      .required('Campo requerido')
      .max(80, 'El campo puede tener como máximo 80 caracteres'),
    description: yup
      .string()
      .required('Campo requerido')
      .min(20, 'El campo debe tener como mínimo 20 caracteres'),
  });

  const form = useNeoforniForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (values) => {
    try {
      const response = await handleAddUpdate(values);
      return response;
    } catch (error) {
      form.setErrorSubmit(error);
      console.error(error);
    };
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormGridContainer>
          <FieldText name="title" label="Tip" />
          <FieldText name="description" label="Descripción" multiline rows={10} />
          <FormAlert isSubmitting={form.isSubmitting}>{form.errorSubmit?.message}</FormAlert>
          <FormGridItem>
            <DangerFormAlert isSubmitting={form.isSubmitting} isAddNewItem={isAddNewItem} />
          </FormGridItem>
        </FormGridContainer>
      </form>
    </FormProvider>
  );
};

TipsForm.propTypes = {
  handleAddUpdate: PropTypes.func,
  tip: PropTypes.object,
};

export default TipsForm;
