
import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, useTheme } from '@material-ui/core';

const FormAlertUpdateMessage = () => {
  return (
    <Typography variant="body2">
      Hola recordá que esta sección va a modificar la información
      existente en la base de datos. Al actualizar, la información anterior se perderá.
    </Typography>
  );
};

const FormAlertAddMessage = () => {
  return (
    <Typography variant="body2">
      Hola recordá que al agregar este elemento se va a modificar la información
      existente en la base de datos.
    </Typography>
  );
};

const DangerFormAlert = ({ isSubmitting = false, isAddNewItem }) => {
  return (
    <Alert
      severity="info"
      variant="outlined"
      color="warning"
      action={(
        <Button
          type="submit"
          variant="outlined"
          disableElevation
          color="secondary"
          size="small"
        >
          {isSubmitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            isAddNewItem ? 'Añadir' : 'Actualizar'
          )}
        </Button>
      )}
    >
      {isAddNewItem ? (
        <FormAlertAddMessage />
      ) : (
        <FormAlertUpdateMessage />
      )}
    </Alert>
  );
};

DangerFormAlert.propTypes = {
  isSubmitting: PropTypes.bool,
  isAddNewItem: PropTypes.bool,
};

export default DangerFormAlert;
