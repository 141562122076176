import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import FieldGeneric from './FieldGeneric';

const FieldText = ({ name, label, required, gridProps, placeholder = '', ...props }) => {
  return (
    <FieldGeneric name={name} gridProps={gridProps}>
      {({ controller, error }) => {
        return (
          <TextField
            name={name}
            label={label}
            variant="outlined"
            color="secondary"
            fullWidth
            helperText={error}
            error={!!error}
            value={controller.field.value}
            onChange={(e) => controller.field.onChange(e.target.value)}
            required={required}
            InputLabelProps={{
              htmlFor: name,
              // eslint-disable-next-line no-unneeded-ternary
              shrink: placeholder ? true : undefined,
            }}
            inputProps={{
              id: name,
            }}
            placeholder={placeholder}
            {...props}
          />
        );
      }}
    </FieldGeneric>
  );
};

FieldText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  gridProps: PropTypes.object,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default FieldText;
