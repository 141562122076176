import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ShowMarkUpText from '../shared/Components/ShowMarkUpText';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    height: '30px',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  creation_date: {
    color: theme.palette.text.disabled,
  },
  description: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
  },
  image: {
    width: '100%',
    maxWidth: '1000px',
  },
  grid: {
    maxWidth: '1000px',
  },
}));

const ViewRecetaPropTypes = {
  receta: PropTypes.object.isRequired,
};

const ViewReceta = ({ receta }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item container xs={12} classes={{ root: classes.grid }}>
        <Grid item xs={12}>
          <img
            src={receta.image}
            className={classes.image}
            alt={receta.name}
          />
          <br />
          <Typography
            classes={{ root: classes.creation_date }}
            variant="caption"
          >
            {`Por: ${
              receta.author
            } | ${receta.creation_date
              .toDate()
              .toLocaleDateString('es-AR', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} classes={{ root: classes.grid }}>
        <Typography classes={{ root: classes.title }} variant="h5">
          {receta.name}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.description }}>
          <ShowMarkUpText text={receta.description} />
        </Typography>
      </Grid>
    </Grid>
  );
};

ViewReceta.propTypes = ViewRecetaPropTypes;

export default ViewReceta;
