import { useContext } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core';
import { FirebaseAdminContext } from '../../Admin/FirebaseAdminContext';
import { Sort } from '@material-ui/icons';

const ButtonSortItems = ({ title, ...props }) => {
  const theme = useTheme();
  const [adminLogged] = useContext(FirebaseAdminContext);
  if (!adminLogged) return null;

  return (
    title ? (
      <Tooltip title={title} placement="top" arrow>
        <IconButton
          variant="outlined"
          style={{ zIndex: theme.zIndex.appBar - 1 }}
          {...props}
        >
          <Sort />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        variant="outlined"
        style={{ zIndex: theme.zIndex.appBar - 1 }}
        {...props}
      >
        <Sort />
      </IconButton>
    )
  );
};

ButtonSortItems.propTypes = {
  title: PropTypes.string,
  showDraggable: PropTypes.bool,
};

export default ButtonSortItems;
