import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    display: 'flex',
    width: 'auto',
    maxWidth: 500,
    maxHeight: '200px',
    height: '150px',
  },
  tab: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    margin: 0,
    padding: 0,
    fontWeight: 400,
    minHeight: '30px',
  },
  tabsScrollButton: {
    minHeight: '15px',
    height: '15px',
  },
  linksTitle: {
    marginBottom: '10px',
  },
}));

const NeoforniLinksTabs = ({
  title,
  links,
  linksLoading,
  tabsProps,
  listTabProps,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Typography variant="h6" align="center" className={classes.linksTitle}>
        {title}
      </Typography>
      {linksLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="150px"
        >
          <Typography variant="caption">Loading...</Typography>
        </Box>
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={title}
          className={classes.tabs}
          TabScrollButtonProps={{ classes: { root: classes.tabsScrollButton } }}
          {...tabsProps}
        >
          {links.map((link, index) => (
            <Tab
              key={link.href}
              id={`vertical-tab-${title}-${index}`}
              href={link.href}
              className={classes.tab}
              onMouseEnter={() => setValue(index)}
              label={<Typography variant="caption">{link.label}</Typography>}
              {...listTabProps}
            />
          ))}
        </Tabs>
      )}
    </div>
  );
};

NeoforniLinksTabs.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  linksLoading: PropTypes.bool,
  tabsProps: PropTypes.object,
  listTabProps: PropTypes.object,
};

export default NeoforniLinksTabs;
