
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import NeoforniLogo from './Logo';
import { IconButton, Paper } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { LayoutContext } from '.';
import { slugify } from '../tools';
import { FirebaseAdminContext } from '../Admin/FirebaseAdminContext';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      position: 'fixed',
      top: 0,
      padding: 0,
      height: '48px',
      minHeight: 0,
      width: '100%',
      zIndex: theme.zIndex.appBar,
    },
    paper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    linkTab: {
      fontSize: '12px',
      textTransform: 'none',
      minWidth: '0px',
      whiteSpace: 'pre',
    },
    tabs: {
      width: '100%',
      maxWidth: '1200px',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .Mui-selected': {
        color: theme.palette.secondary.main,
      },
    },
  };
});

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

const LinkTab = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Tab
      className={classes.linkTab}
      component="a"
      onClick={(event) => {
        event.preventDefault();
        if (props.href) history.push(slugify(props.href));
      }}
      {...props}
    />
  );
};

const LinkTabMenu = ({ items, label, href, ...props }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <LinkTab
        label={label}
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        {...props}
      />
      <Menu
        id="customized-menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item) => {
          return (
            <MenuItem
              key={item.label}
              selected={window.location.pathname.includes(item.href)}
              onClick={(e) => {
                e.preventDefault();
                if (item.href) history.push(slugify(`${href}${item.href}`));
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const LogoMenuItem = () => {
  const ctx = useContext(LayoutContext);
  const history = useHistory();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      height="48px"
    >
      <IconButton onClick={() => ctx.setOpenSidebar((prev) => !prev)}>
        <MenuIcon />
      </IconButton>
      <NeoforniLogo width="100px" onClick={() => history.push('/home')} />
    </Box>
  );
};

const LinkTabs = () => {
  const classes = useStyles();
  const ctx = useContext(LayoutContext);
  const [adminLogged] = useContext(FirebaseAdminContext);

  return (
    <Tabs
      className={classes.tabs}
      variant="fullWidth"
      value={ctx.currentTab}
      aria-label="nav tabs example"
    >
      {ctx.sections.map((section, index) => {
        if (section.adminRoute && !adminLogged) return null;
        return (
          <LinkTab
            key={section.label}
            label={section.label}
            href={section.href}
            {...a11yProps(index)}
          />
        );
      })}
    </Tabs>
  );
};

const NavTabs = () => {
  const classes = useStyles();
  const ctx = useContext(LayoutContext);

  return (
    <Toolbar
      className={classes.toolbar}
      disableGutters
    >
      <Paper className={classes.paper} elevation={3} square>
        {ctx.showMenuIcon ? (
          <LogoMenuItem />
        ) : (
          <LinkTabs />
        )}
      </Paper>
    </Toolbar>
  );
};

LinkTab.propTypes = {
  label: PropTypes.node.isRequired,
  href: PropTypes.string,
};

LinkTabMenu.propTypes = {
  label: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
  href: PropTypes.string,
};

export default NavTabs;
