import { addItem, updateItem } from '../firebase';

const updateTallerCurso = async (tallerCurso, id) => {
  return updateItem(tallerCurso, id, 'cursos');
};

const addTallerCurso = async (tallerCurso) => {
  return addItem(tallerCurso, 'cursos');
};

const prepareToJson = (tallerCurso) => {
  const {
    image, video, media_type: mediaType, ...restTallerCurso
  } = tallerCurso;

  return mediaType === 'image'
    ? {
        ...restTallerCurso,
        image,
      } : {
        ...restTallerCurso,
        video,
      };
};

export {
  updateTallerCurso,
  addTallerCurso,
  prepareToJson,
};
