import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Tooltip } from '@material-ui/core';
import NeoforniCard from '../Containers/NeoforniCard';
import { FirebaseAdminContext } from '../../Admin/FirebaseAdminContext';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: '20px',
  },
}));

const EditableSection = ({
  children,
  Editable = <div>Editable section</div>,
}) => {
  const classes = useStyles();
  const [adminLogged] = useContext(FirebaseAdminContext);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [showEditable, setShowEditable] = useState(false);

  useEffect(() => {
    if (showEditable) setShowCloseIcon(true);
    else setShowCloseIcon(false);
  }, [showEditable]);

  const handleClickEditableZone = () => {
    if (adminLogged) setShowEditable(true);
  };

  const handleClickEditIcon = (e) => {
    e.stopPropagation(); // to not run handleClickEditableZone
    setShowEditable((prev) => !prev);
  };

  return (
    <Tooltip
      title={(adminLogged && !showEditable) ? 'Doble click para editar esta información' : ''}
      arrow
      placement="top"
    >
      <Box
        onDoubleClick={handleClickEditableZone}
        position="relative"
        height="100%"
        role="editableBox"
        style={{ cursor: adminLogged ? 'pointer' : 'default' }}
      >
        {showEditable ? (
          <NeoforniCard classes={classes} elevation={20} variant="outlined">
            <Box position="absolute" right="5px" top="5px">
              {showCloseIcon && (
                <IconButton
                  role="cancelbutton"
                  onClick={handleClickEditIcon}
                  size="small"
                >
                  <CancelIcon />
                </IconButton>
              )}
            </Box>
            {Editable}
          </NeoforniCard>
        ) : (
          children
        )}
      </Box>
    </Tooltip>
  );
};

EditableSection.propTypes = {
  children: PropTypes.node,
  Editable: PropTypes.node,
};

export default EditableSection;
