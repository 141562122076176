import React from 'react';
import PropTypes from 'prop-types';
import NeoforniCardMedia from '../../shared/Components/NeoforniCardMedia';
import YoutubeEmbed from '../../shared/Components/YoutubeEmbed';

const VideoDelHornoPropTypes = {
  title: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
};

const VideoDelHorno = ({ title, video }) => {
  const useMemoizedComponent = () =>
    React.useMemo(() => {
      return (
        <NeoforniCardMedia
          titulo={title}
          subtitulo="Demostración del producto"
          component={() => <YoutubeEmbed src={video} />}
          CardProps={{
            transparent: true,
            style: {
              padding: 0,
              height: '100%',
            },
          }}
        />
      );
    }, [video]);

  const children = useMemoizedComponent();
  return children;
};

VideoDelHorno.propTypes = VideoDelHornoPropTypes;

export default VideoDelHorno;
