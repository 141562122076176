import { useRef } from 'react';
import PropTypes from 'prop-types';
import { addItem } from '../firebase';
import NeoforniModal from '../shared/Components/NeoforniModal';
import ButtonNewItem from '../shared/Components/ButtonNewItem';
import VideosForm from './VideosForm';

const AddVideosPropTypes = {
  reloadPage: PropTypes.func.isRequired,
};

const AddVideos = ({ reloadPage }) => {
  const modalAddNewVideoRef = useRef();

  const handleClickAddNewVideo = () => {
    modalAddNewVideoRef.current.handleToggleModal();
  };

  const handleAddVideo = async (apiFormValues) => {
    try {
      const [newApiFormValues] = apiFormValues;
      const response = await addItem(newApiFormValues, 'videos');
      modalAddNewVideoRef.current.handleToggleModal();
      reloadPage();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <>
      <ButtonNewItem
        style={{ float: 'right' }}
        onClick={handleClickAddNewVideo}
      >
        Agregar nuevo video
      </ButtonNewItem>
      <NeoforniModal title="Nuevo video" ref={modalAddNewVideoRef}>
        <VideosForm handleAddUpdate={handleAddVideo} />
      </NeoforniModal>
    </>
  );
};

AddVideos.propTypes = AddVideosPropTypes;

export default AddVideos;
