import React from 'react';
import { getCollectionData } from '../firebase';
import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';
import { usePromiseToState } from '../tools';
import { useTheme } from '@material-ui/core';
import TenesMasDudasContactanos from '../shared/Components/TenesMasDudasContactanos';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import ViewTip from './ViewTip';
import UpdateTip from './UpdateTip';
import AddTip from './AddTip';

const Tips = () => {
  const theme = useTheme();
  const [tipsState,, reloadPage] = usePromiseToState({
    promise: () => getCollectionData('tips'),
  });

  return (
    <>
      <NeoforniContainer maxWidth="lg" style={{ padding: theme.spacing(2) }}>
        <AddTip reloadPage={reloadPage} />
        <LoadingErrorWrapper spinner {...tipsState}>
          {tipsState?.data.map((tip) => (
            <UpdateTip key={tip.title} tip={tip} reloadPage={reloadPage}>
              <ViewTip
                title={tip.title}
                key={tip.description}
                description={tip.description}
              />
            </UpdateTip>
          ))}
        </LoadingErrorWrapper>
      </NeoforniContainer>
      <TenesMasDudasContactanos />
    </>
  );
};

export default Tips;
