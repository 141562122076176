import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import NeoforniMultipleCarousel from '../../shared/Components/NeoforniMultipleCarousel';
import { Typography } from '@material-ui/core';

const AccesoriosPropTypes = {
  accesories: PropTypes.array.isRequired,
};

const Accesorios = ({ accesories }) => {
  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} justify="center" alignItems="center">
        <Typography variant="h5">Accesorios</Typography>
      </Grid>
      <Grid item container xs={12} justify="center" alignItems="center">
        <NeoforniMultipleCarousel items={accesories} includeCaption />
      </Grid>
    </Grid>
  );
};

Accesorios.propTypes = AccesoriosPropTypes;

export default Accesorios;
