import firebase from 'firebase';
import 'firebase/storage';
import { devconsole } from './tools';

const isDevelop = process.env.NODE_ENV === 'development';
const firebaseConfigProduction = {
  apiKey: 'AIzaSyAJjtPyJEyIFGDMHvv5c6yQBlVpYaa6lUk',
  authDomain: 'neoforni-web-app-d3d05.firebaseapp.com',
  projectId: 'neoforni-web-app-d3d05',
  storageBucket: 'neoforni-web-app-d3d05.appspot.com',
  messagingSenderId: '313217898509',
  appId: '1:313217898509:web:61e610320030c26ac59d44',
};

const firebaseConfigDevelop = {
  apiKey: 'AIzaSyAXurzF2poA4RNBAnxTBHg0-3HUkTl0HqU',
  authDomain: 'neoforni-dev.firebaseapp.com',
  projectId: 'neoforni-dev',
  storageBucket: 'neoforni-dev.appspot.com',
  messagingSenderId: '717198996416',
  appId: '1:717198996416:web:8fd072da8fc90cd9f70e25',
};

const firebaseConfig = isDevelop ? firebaseConfigDevelop : firebaseConfigProduction;
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Set session persistence
// https://firebase.google.com/docs/auth/web/auth-state-persistence
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
//   .then((d) => {
//     // Existing and future Auth states are now persisted in the current
//     // session only. Closing the window would clear any existing state even
//     // if a user forgets to sign out.
//     // ...
//     // New sign-in will be persisted with session persistence.
//     // return firebase.auth().signInWithEmailAndPassword(email, password)
//   })
//   .catch((error) => {
//     const errorMessage = error.message;
//     throw errorMessage;
//   });

const database = firebase.firestore();

const getItem = async (id, collection) => {
  try {
    const item = await database.collection(collection).doc(id).get();
    if (!item.exists) throw new Error(`Item did not exist ${id}`);
    const response = {
      fid: item.id,
      ...item.data(),
    };
    devconsole.log(`geting new item from collection: ${collection}`, item.id);
    return response;
  } catch (e) {
    devconsole.error(e);
    throw new Error(e);
  }
};

const addItem = async (item, collection) => {
  try {
    if (!firebase.auth().currentUser) throw new Error('Not authorized');
    const doc = database.collection(collection).doc();
    await doc.set({ fid: doc.id, ...item });
  } catch (e) {
    devconsole.error(e);
    throw new Error(e);
  }
};

const updateItem = async (item, id, collection) => {
  try {
    if (!firebase.auth().currentUser) throw new Error('Not authorized');
    await database.collection(collection).doc(id).update(item);
    devconsole.log(`updating item from collection ${collection}`, id);
  } catch (e) {
    devconsole.error(e);
    throw new Error(e);
  }
};

const updateItems = async (items, collection) => {
  const promises = [];
  items.forEach((item) => {
    promises.push(updateItem(item, item.fid, collection));
  });
  try {
    await Promise.all(promises);
  } catch (e) {
    devconsole.error(e);
    throw new Error(e);
  }
};

const deleteItem = async (id, collection) => await database.collection(collection).doc(id).delete();

const getCollectionData = async (collection) => {
  return new Promise((resolve, reject) => {
    database.collection(collection).onSnapshot(async (querySnapshot) => {
      const isEmpty = await querySnapshot.empty;
      if (isEmpty) reject(new Error(`Collection '${collection}' not exist or is empty`));
      const items = [];
      devconsole.log(`...getting items from collection: ${collection}`);
      querySnapshot.forEach((item) => {
        const newItem = {
          fid: item.id,
          ...item.data(),
        };
        items.push(newItem);
      });
      if (items.length > 0) {
        resolve(items.sort());
      }
    });
  });
};

// storage
const storage = firebase.storage();
const getFileRef = ({ folderPath, fileName }) => {
  const storageRef = storage.ref();
  let fileRef;
  if (folderPath) {
    const folderRef = storageRef.child(folderPath);
    fileRef = folderRef.child(fileName);
  } else {
    fileRef = storageRef.child(fileName);
  }
  return fileRef;
};

const getUrlFileRef = (url) => {
  const urlRef = storage.refFromURL(url);
  return urlRef;
};

export {
  firebase,
  getCollectionData,
  addItem,
  getItem,
  updateItem,
  updateItems,
  deleteItem,
  getFileRef,
  getUrlFileRef,
};
