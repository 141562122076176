import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import { getCollectionData } from '../firebase';
import { usePromiseToState, convertReadableToKey, historyPushSlugify } from '../tools';
import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import CardPresentationShadow from '../shared/Components/CardPresentationShadow';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import NeoforniBreadcrumbs from '../shared/Components/NeoforniBreadcrumbs';
import AddReceta from './AddReceta';

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    zIndex: theme.zIndex.appBar - 1,
  },
  tab: {
    color: theme.palette.text.primary,
    textTransform: 'none',
  },
  tabPanel: {
    maxWidth: '1000px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px',
    },
  },
}));

// TODO: abstract TabsRecetas and PanelRecetas and move it to shared/components.

const Recetas = () => {
  const [recetas,, reloadPage] = usePromiseToState({
    promise: () => getCollectionData('recetas'),
  });
  const saladas = recetas.data?.filter((receta) => receta.type === 'salada');
  const dulces = recetas.data?.filter((receta) => receta.type === 'dulce');
  const recetasSaladasDulces = recetas?.data;
  const items = [
    { label: 'Todas', recetas: recetasSaladasDulces },
    { label: 'Saladas', recetas: saladas },
    { label: 'Dulces', recetas: dulces },
  ];

  return (
    <NeoforniContainer maxWidth="lg">
      <AddReceta reloadPage={reloadPage} />
      <LoadingErrorWrapper spinner {...recetas}>
        <NeoforniBreadcrumbs />
        <Grid
          item
          xs={12}
          container
          spacing={2}
          justify="center"
          alignItems="center"
        >
          <TabsRecetas items={items} ItemPanel={PanelRecetas} />
        </Grid>
      </LoadingErrorWrapper>
    </NeoforniContainer>
  );
};

const PanelRecetasPropTypes = {
  recetas: PropTypes.array,
  grow: PropTypes.any,
};

const PanelRecetas = ({ recetas, grow }) => {
  const history = useHistory();
  const GridItemProps = recetas.length > 1 ? {
    xs: 12,
    sm: 6,
    md: 4,
  } : {
    xs: true,
  };

  const handleClickReceta = (receta) => {
    const path = `recetas/${convertReadableToKey(receta.name)}/${receta.fid}`;
    historyPushSlugify(path, history);
  };

  return (
    <Grid container spacing={2}>
      {recetas.map((receta, idx) => {
        return (
          <Fade key={receta.fid} in={grow} timeout={{ enter: 500, exit: 500 }}>
            <Grid
              key={receta.fid}
              item
              container
              justify="center"
              alignItems="center"
              {...GridItemProps}
            >
              <CardPresentationShadow
                title={receta.name}
                image={receta.image}
                onClick={() => handleClickReceta(receta)}
              />
            </Grid>
          </Fade>
        );
      })}
    </Grid>
  );
};

const TabsRecetasPropTypes = {
  items: PropTypes.array,
  ItemPanel: PropTypes.func,
};

const TabsRecetas = ({ items, ItemPanel }) => {
  const classes = useStyles();
  const [value, setValue] = useState('0');
  const [closingGrowAttempt, setClosingGrowAttempt] = useState(true);

  const handleChange = (event, newValue) => {
    setClosingGrowAttempt(false);
    setTimeout(() => {
      setValue(newValue);
      setClosingGrowAttempt(true);
    }, 500);
  };

  return (
    <TabContext value={value}>
      <AppBar
        classes={{ root: classes.appBar }}
        position="static"
        elevation={0}
      >
        <TabList onChange={handleChange} aria-label="tabs_example">
          {items.map((item, idx) => (
            <Tab
              key={item.recetas}
              classes={{ root: classes.tab }}
              label={idx > 0 ? `${item.label} (${item.recetas.length})` : item.label}
              value={idx.toString()}
            />
          ))}
        </TabList>
      </AppBar>
      {items.map((item, idx) => {
        return (
          <TabPanel
            classes={{ root: classes.tabPanel }}
            key={item.recetas}
            value={idx.toString()}
          >
            <ItemPanel recetas={item.recetas} grow={closingGrowAttempt} />
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

TabsRecetas.propTypes = TabsRecetasPropTypes;
PanelRecetas.propTypes = PanelRecetasPropTypes;

export default Recetas;
