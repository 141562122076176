import { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import NeoforniBreadcrumbs from '../shared/Components/NeoforniBreadcrumbs';
import CursoTitleDescriptionForm from './CursoTitleDescriptionForm';
import { addTallerCurso } from './talleresCursosHelper';
import NeoforniModal from '../shared/Components/NeoforniModal';
import ButtonNewItem from '../shared/Components/ButtonNewItem';

const AddTallerCursoPropTypes = {
  reloadPage: PropTypes.func.isRequired,
};

const AddTallerCurso = ({ reloadPage }) => {
  const modalRef = useRef();

  const handleAdd = async (values) => {
    try {
      const response = await addTallerCurso(values);
      reloadPage();
      modalRef.current.handleToggleModal();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <>
      <Box width="100%" display="flex" justifyContent="space-between" mb={2}>
        <NeoforniBreadcrumbs style={{ marginBottom: 0 }} />
        <ButtonNewItem onClick={() => modalRef.current.handleToggleModal()}>Agregar nuevo curso</ButtonNewItem>
      </Box>
      <NeoforniModal title="Nuevo curso" ref={modalRef} maxWidth="md">
        <CursoTitleDescriptionForm
          handleAddUpdate={handleAdd}
        />
      </NeoforniModal>
    </>
  );
};

AddTallerCurso.propTypes = AddTallerCursoPropTypes;

export default AddTallerCurso;
