
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronDown from '@material-ui/icons/ExpandMore';
import { LayoutContext } from '.';
import { historyPushSlugify } from '../tools';
import { FirebaseAdminContext } from '../Admin/FirebaseAdminContext';

const useStyles = makeStyles((theme) => ({
  chevron: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  nestedListItem: {
    color: 'rgb(100, 100, 100)',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2px 10px',
    },
  },
  listItem: {
    color: 'rgb(100, 100, 100)',
    [theme.breakpoints.up('sm')]: {
      padding: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
  },
  listItemSelected: {
    color: theme.palette.text.primary,
  },
  listItemText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
  },
  drawer: {
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    [theme.breakpoints.up('sm')]: {
      width: 240,
    },
  },
  drawerPaper: {
    // topbar height
    top: '48px',
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    [theme.breakpoints.up('sm')]: {
      width: 240,
    },
  },
  drawerBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}));

const ListItemGroup = ({ label, items, onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const hrefs = items.map((item) => item.href);
    if (hrefs.some((item) => window.location.pathname.includes(item))) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [window.location.pathname]);

  return (
    <>
      <ListItem
        className={classes.listItem}
        classes={{
          selected: classes.listItemSelected,
        }}
        selected={selected}
        button onClick={() => setOpen((prev) => !prev)}
      >
        <ListItemText
          primaryTypographyProps={{
            classes: { root: classes.listItemText },
          }}
          primary={label}
        />
        {open ? (
          <IconButton size="small"><ChevronDown className={classes.chevron} /></IconButton>
        ) : (
          <IconButton size="small"><ChevronRight className={classes.chevron} /></IconButton>
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item) => (
            <ListItem
              button
              className={classes.nestedListItem}
              classes={{
                selected: classes.listItemSelected,
              }}
              selected={window.location.pathname.includes(item.href)}
              onClick={(e) => onClick(e, item.href)}
              key={item.label}
            >
              <ListItemText
                primaryTypographyProps={{
                  classes: { root: classes.listItemText },
                }}
                primary={item.label}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const Sidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const ctx = useContext(LayoutContext);
  const [adminLogged] = useContext(FirebaseAdminContext);

  const handleClickListItem = (e, href) => {
    if (href) historyPushSlugify(href, history);
    ctx.setOpenSidebar(false);
  };

  const sectionsOmittedPredicate = ({ href }) => {
    if (!adminLogged) return href !== '/home' && href !== '/configuracion';

    return href !== '/home';
  };

  return (
    <nav aria-label="mailbox folders">
      <Drawer
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
        PaperProps={{
          elevation: 24,
        }}
        variant="temporary"
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.drawerBackdrop,
            },
          },
        }}
        open={ctx.openSidebar}
        onClose={() => ctx.setOpenSidebar(false)}
      >
        {ctx.sections.filter(sectionsOmittedPredicate).map((section) => {
          return (
            section.isGroup ? (
              <ListItemGroup key={section.label} label={section.label} items={section.items} onClick={(e, href) => handleClickListItem(e, `${section.href}${href}`)} />
            ) : (
              <ListItem
                classes={{
                  root: classes.listItem,
                  selected: classes.listItemSelected,
                }}
                selected={window.location.pathname.includes(section.href)}
                button
                key={section.label}
                onClick={(e) => handleClickListItem(e, section.href)}
              >
                <ListItemText
                  primaryTypographyProps={{
                    classes: { root: classes.listItemText },
                  }}
                >
                  {section.label}
                </ListItemText>
              </ListItem>
            )
          );
        })}
      </Drawer>
    </nav>
  );
};

ListItemGroup.propTypes = {
  label: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export default Sidebar;
