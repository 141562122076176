import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 0,
    overflow: 'hidden',
    paddingBottom: '56.25%',
    paddingTop: '30px',
    position: 'relative',
    '& iframe, .video-responsive object, .video-responsive embed': {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
}));

const YoutubeEmbed = ({
  embedId,
  src,
  autoPlay,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <iframe
        width="100%"
        height="100%"
        src={autoPlay ? setAutoPlay(src) : src}
        frameBorder="0"
        style={{ border: 0, margin: 0 }}
        allow={autoPlay && 'autoplay'}
        allowFullScreen
        encriptedMedia
        {...props}
      />
    </div>
  );
};

function setAutoPlay(videoSrc) {
  return videoSrc.concat('?autoplay=1&mute=1&loop=1');
}

YoutubeEmbed.propTypes = {
  src: PropTypes.string.isRequired,
  embedId: PropTypes.string,
  autoPlay: PropTypes.bool,
};

export default YoutubeEmbed;
