import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = props => makeStyles(() => ({
  box: {
    position: 'relative',
    backgroundColor: 'black',
    backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.0) 0%, rgba(185,185,185,0.0) 25%, rgba(130,130,130,0.0) 50%, rgba(0,0,0,0.4) 75%), url(${props.image})`,
    backgroundSize: 'cover',
    width: '300px',
    height: '300px',
    '&:hover': {
      cursor: 'pointer',
      backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.0) 0%, rgba(185,185,185,0.0) 25%, rgba(130,130,130,0.1) 50%, rgba(0,0,0,0.7) 80%), url(${props.image})`,
    },
  },
  title: {
    fontSize: props.titleWordCount > 25 ? '16px' : '18px',
    position: 'absolute',
    top: 245,
    left: 15,
    color: '#fff',
  },
}));

const CardPresentationShadow = ({ title, image, ...props }) => {
  const classes = useStyles({ image, titleWordCount: title.length })();
  return (
    <Box classes={{ root: classes.box }} {...props}>
      <Typography classes={{ root: classes.title }} variant="h5">
        {title}
      </Typography>
    </Box>
  );
};

CardPresentationShadow.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default CardPresentationShadow;
