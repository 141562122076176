import _ from 'lodash';
import Divider from '@material-ui/core/Divider';
import { getCollectionData } from '../firebase';
import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';
import NeoforniCard from '../shared/Containers/NeoforniCard';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import PanelTabs from './PanelTabs';
import { usePromiseToState } from '../tools';
import { buildSectionItem } from './panelHelper';
import { useState } from 'react';
import DeleteDocument from './DeleteDocument';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const SECTIONS = [
  { collection: 'productos', attribute: 'name' },
  { collection: 'cursos', attribute: 'name' },
  { collection: 'recetas', attribute: 'name' },
  { collection: 'tips', attribute: 'title' },
  { collection: 'videos', attribute: 'title' },
];

const useCardStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
  cardHeader: {
    padding: '10px',
  },
  cardContent: {
    padding: '20px',
  },
}));

const Panel = () => {
  const cardClasses = useCardStyles();
  const [tabPosition, setTabPosition] = useState('0');
  const [currentCollection, setCurrentCollection] = useState(SECTIONS[0]);
  const [collectionState,, reload] = usePromiseToState({
    promise: () => getCollectionData(currentCollection.collection),
    deps: [currentCollection],
  });
  const transformFunction = ({ collection, attribute }) => buildSectionItem({ section: collection, attribute });
  const collections = SECTIONS.map(transformFunction);

  const handleChangeTabPosition = (_, newTabPosition) => {
    setCurrentCollection(SECTIONS[newTabPosition]);
    setTabPosition(newTabPosition);
  };

  return (
    <NeoforniContainer maxWidth="md">
      <PanelTabs
        items={collections}
        tabPosition={tabPosition}
        handleChange={handleChangeTabPosition}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>Atención!</AlertTitle>
              Los documentos que elimines no se podrán recuperar
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <NeoforniCard title={`Borrar ${currentCollection.collection}`} classes={cardClasses} elevation={2}>
              <LoadingErrorWrapper spinner {...collectionState}>
                {collectionState.data.map((document) => (
                  <>
                    <Divider />
                    <DeleteDocument
                      key={document.fid}
                      document={document}
                      collection={currentCollection.collection}
                      reloadPage={reload}
                      label={document[currentCollection.attribute]}
                    />
                  </>
                ))}
              </LoadingErrorWrapper>
            </NeoforniCard>
          </Grid>
        </Grid>
      </PanelTabs>
    </NeoforniContainer>
  );
};

export default Panel;
