import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MUICard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  cardContent: {
    padding: '14px 0',
  },
  cardHeader: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
    },
  },
  cardSubHeader: {
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
}));

const NeoforniCard = ({
  maxWidth = '100%',
  maxHeight = '100%',
  transparent,
  center,
  title,
  subtitle,
  children,
  classes,
  ...props
}) => {
  const myClasses = useStyles();

  return (
    <MUICard
      elevation={0}
      classes={{
        root: clsx(myClasses.root, classes?.root),
      }}
      {...props}
    >
      {(title || subtitle) && (
        <CardHeader
          title={title}
          subheader={subtitle}
          classes={{
            root: clsx(myClasses.cardHeader, classes?.cardHeader),
          }}
          titleTypographyProps={{
            classes: {
              root: clsx(myClasses.cardHeader, classes?.cardHeader),
            },
          }}
          subheaderTypographyProps={{
            color: 'secondary',
            classes: {
              root: clsx(myClasses.cardSubHeader, classes?.cardSubHeader),
            },
          }}
        />
      )}
      <CardContent
        classes={{ root: clsx(myClasses.cardContent, classes?.cardContent) }}
      >
        {children}
      </CardContent>
    </MUICard>
  );
};

NeoforniCard.propTypes = {
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  center: PropTypes.bool,
  classes: PropTypes.object,
  transparent: PropTypes.bool,
};

export default NeoforniCard;
