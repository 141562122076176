/* eslint-disable react/prop-types */

import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import NeoforniCard from '../Containers/NeoforniCard';

const NeoforniCardMedia = ({ titulo, subtitulo, component, CardProps, ...props }) => {
  return (
    <NeoforniCard title={titulo} subtitle={subtitulo} {...CardProps}>
      <CardMedia
        component={component}
        {...props}
      />
    </NeoforniCard>
  );
};

export default NeoforniCardMedia;
