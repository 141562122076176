import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import logo from '../assets/logo.png';

const NeoforniLogo = (props) => {
  return (
    <img src={logo} width="150px" alt="neoforni_Logo" {...props} />
  );
};

const NeoforniLogoConBajada = (props) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <NeoforniLogo {...props} />
      <Box mt={2}>
        <Typography variant="subtitle1">La evolución del horno a leña</Typography>
      </Box>
    </Box>
  );
};

export default NeoforniLogo;
export { NeoforniLogoConBajada };
