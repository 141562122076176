import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import UpdateVideos from './UpdateVideos';
import { getCollectionData } from '../firebase';
import { useBreakpoint, usePromiseToState } from '../tools/index';
import ViewVideos from './ViewVideos';
import AddVideos from './AddVideos';
import TenesMasDudasContactanos from '../shared/Components/TenesMasDudasContactanos';
import { useTheme } from '@material-ui/core';

const Videos = () => {
  const theme = useTheme();
  const { value: breakpointValue } = useBreakpoint();
  const [videosState,, reloadPage] = usePromiseToState({
    promise: () => getCollectionData('videos'),
  });

  return (
    <>
      <NeoforniContainer maxWidth={breakpointValue < 2 ? 'sm' : 'md'}>
        <LoadingErrorWrapper spinner {...videosState}>
          <UpdateVideos videos={videosState?.data} reloadPage={reloadPage}>
            <AddVideos reloadPage={reloadPage} />
            <ViewVideos videos={videosState?.data} />
          </UpdateVideos>
        </LoadingErrorWrapper>
      </NeoforniContainer>
      <TenesMasDudasContactanos style={{ marginTop: theme.spacing(8) }} />
    </>
  );
};

export default Videos;
