import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { throttle } from 'lodash';
import { useParams } from 'react-router';
import { NAVIGATION_TITLE_DEFAULT } from '../shared/global';

/* eslint-disable no-useless-escape */
function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string.toString()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    // .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

const convertKeyToReadable = (key) => {
  const readable = key
    .charAt(0).toUpperCase() + key.slice(1)
    .split('-')
    .join(' ');

  return readable;
};

const convertReadableToKey = (readable) => {
  const wordsJoined = readable
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-');

  return wordsJoined.charAt(0).toLowerCase() + wordsJoined.slice(1);
};

const usePromiseToState = ({ promise, deps = [] }) => {
  const initialState = { data: [], loading: true, error: '' };
  const [items, setItems] = useState(initialState);
  const [dummyCount, setDummyCount] = useState(0);

  const reload = () => {
    setDummyCount(dummyCount + 1);
  };

  useEffect(async () => {
    setItems(initialState);
    try {
      const data = await promise();
      if (Array.isArray(data)) setItems({ data, loading: false, error: '' });
      else setItems({ data: [data], loading: false, error: '' });
    } catch (error) {
      setItems({ data: [], loading: false, error: error.toString() });
    }
  }, [dummyCount, ...deps]);

  return [items, setItems, reload];
};

const useBreakpoint = () => {
  const theme = useTheme();
  /* eslint-disable-next-line consistent-return */
  const getDeviceConfig = (width) => {
    const { values } = theme.breakpoints;
    if (width < values.sm) {
      return { breakpoint: 'xs', value: 0 };
      /* eslint-disable-next-line no-else-return */
    } else if (width >= values.sm && width < values.md) {
      return { breakpoint: 'sm', value: 1 };
    } else if (width >= values.md && width < values.lg) {
      return { breakpoint: 'md', value: 2 };
    } else if (width >= values.lg && width < values.xl) {
      return { breakpoint: 'lg', value: 3 };
    } else if (width >= values.xl) {
      return { breakpoint: 'xl', value: 4 };
    }
  };

  const [breakPoint, setBreakPoint] = useState(() => getDeviceConfig(window.innerWidth, theme));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBreakPoint(getDeviceConfig(window.innerWidth, theme));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakPoint;
};

const useBreadcrumb = () => {
  const pathname = window.location.pathname;
  const { id } = useParams();
  const [, ...paths] = pathname
    .split('/')
    // filtering the ids because aren't showable
    .filter((path) => path !== id)
    .map((path) => ({ label: convertKeyToReadable(path), href: `/${path}` }));
  return [
    { label: 'Home', href: '/home' },
    ...paths,
  ];
};

const log = (type = 'log', ...props) => {
  if (process.env.NODE_ENV === 'development') {
    const newProps = props.map((x) => (
      (typeof x !== 'object' || x instanceof Error) ? x : JSON.stringify(x)
    ));
    try {
      // appends caller info https://stackoverflow.com/questions/1340872
      const callerLine = new Error().stack.toString().split('\n')[2];
      newProps.push(`@${callerLine.trim().split(' ')[1]}`);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    console[type](
      '%c%s',
      'color: rgb(7, 165, 81); font-size: 14px; font-weight: 600; font-family: Helvetica',
      ...newProps,
    );
  }
};

const table = (...props) => {
  if (process.env.NODE_ENV === 'development') {
    console.table(props);
  }
};

const historyPushSlugify = (path, history) => {
  history.push(slugify(path));
};

const devconsole = {
  log: (props) => log('log', props),
  table,
  error: (props) => log('error', props),
};

const isEmpty = (obj) => {
  if (!obj || Object.entries(obj).length <= 0) return true;
  return false;
};

const isNotEmpty = (obj) => !isEmpty(obj);

const isFloat = (number) => !!(number % 1);

export {
  slugify,
  usePromiseToState,
  useBreakpoint,
  useBreadcrumb,
  historyPushSlugify,
  convertKeyToReadable,
  convertReadableToKey,
  devconsole,
  isEmpty,
  isNotEmpty,
  isFloat,
};
