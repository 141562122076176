import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import NeoforniCard from '../../shared/Containers/NeoforniCard';
import ShowMarkUpText from '../../shared/Components/ShowMarkUpText';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: 'linear-gradient(90deg, rgba(232,232,232,1) 0%, rgba(255,255,255,1) 100%)',
  },
  cardHeader: {
    paddingLeft: '10px',
  },
}));

const CaracteristicasDelHornoPropTypes = {
  features: PropTypes.string.isRequired,
  technicalImage: PropTypes.string.isRequired,
};

const CaracteristicasDelHorno = ({
  features,
  technicalImage,
}) => {
  const classes = useStyles();

  return (
    <NeoforniCard
      title="Características"
      variant="outlined"
      classes={classes}
      square
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={7}>
          <ShowMarkUpText text={features} />
        </Grid>
        <Grid item container alignItems="center" xs={12} sm={6} md={5}>
          <img src={technicalImage} width="100%" />
        </Grid>
      </Grid>
    </NeoforniCard>
  );
};

CaracteristicasDelHorno.propTypes = CaracteristicasDelHornoPropTypes;

export default CaracteristicasDelHorno;
