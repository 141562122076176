import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import FieldText from './FieldText';
import FormGridItem from './FormGridItem';

const FieldTextImagePreview = ({
  name,
  label,
  required,
  gridProps,
  placeholder,
  ...props
}) => {
  const form = useFormContext();
  const field = useWatch({
    control: form.control,
    name,
  });

  // const showImg = field.includes('https://neoforni.com.ar/images/');
  const showImg = true;

  return (
    <FormGridItem container spacing={2} {...gridProps}>
      <FieldText
        name={name}
        label={label}
        required={required}
        placeholder={placeholder}
        multiline
        rows={5}
        gridProps={{
          xs: showImg ? 10 : 12,
        }}
        {...props}
      />
      {showImg && (
        <FormGridItem container xs={2} alignItems="center">
          <img
            width="100px"
            height="100px"
            src={field}
            style={{ backgroundColor: '#eee' }}
          />
        </FormGridItem>
      )}
    </FormGridItem>
  );
};

FieldTextImagePreview.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  gridProps: PropTypes.object,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default FieldTextImagePreview;
