import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    zIndex: theme.zIndex.appBar - 1,
  },
  tab: {
    color: theme.palette.text.primary,
    textTransform: 'none',
  },
  tabPanel: {
    maxWidth: '1000px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px',
    },
  },
}));

const PanelTabsPropTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
  tabPosition: PropTypes.number,
  handleChange: PropTypes.func,
};

const PanelTabs = ({
  items = [],
  tabPosition,
  handleChange,
  children,
}) => {
  const classes = useStyles();

  return (
    <TabContext value={tabPosition}>
      <AppBar
        classes={{ root: classes.appBar }}
        position="static"
        elevation={0}
      >
        <TabList onChange={handleChange} aria-label="tabs_example">
          {items.map((item, idx) => (
            <Tab
              key={item.section}
              classes={{ root: classes.tab }}
              label={item.label}
              value={idx.toString()}
            />
          ))}
        </TabList>
      </AppBar>
      {items.map((item, idx) => {
        return (
          <TabPanel
            classes={{ root: classes.tabPanel }}
            key={item.recetas}
            value={idx.toString()}
          >
            {children}
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

PanelTabs.propTypes = PanelTabsPropTypes;

export default PanelTabs;
