import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import { NeoforniLogoConBajada } from './Logo';
import logoCampingDevAndDesign from '../assets/camping.svg';
import { Box, Typography, useTheme } from '@material-ui/core';
import { getCollectionData } from '../firebase';
import { usePromiseToState, slugify } from '../tools';
import { getProductPath } from '../Productos/productosHelper';
import NeoforniLinksTabs from '../shared/Components/NeoforniLinksList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: 'transparent',
    height: '250px',
    [theme.breakpoints.down('md')]: {
      height: '500px',
    },
    [theme.breakpoints.up('md')]: {
      height: '250px',
    },
  },
  boxLinks: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
  },
}));

const sectionTitleLinks = (productsURL, linksLoading) => {
  return [
    {
      title: 'Productos',
      links: productsURL,
      linksLoading,
    },
    {
      title: 'Otros enlaces',
      links: [
        { href: '/recetas', label: 'Nuestras recetas' },
        { href: '/talleres-y-cursos', label: 'Talleres y cursos' },
        { href: '/tips', label: 'Preguntas frecuentes' },
        { href: '/contactanos', label: 'Contactanos' },
      ],
    },
  ];
};

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [productsState] = usePromiseToState({
    promise: () => getCollectionData('productos'),
  });
  const productsURLs = productsState.data.map((product) => ({
    href: slugify('\\' + getProductPath(product)),
    label: product.name,
  }));
  return (
    <>
      <NeoforniContainer
        center
        style={{
          padding: theme.spacing(3),
          marginTop: theme.spacing(0),
          position: 'relative',
          bottom: 0,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box height="100%" display="flex" justifyContent="center">
              <NeoforniLogoConBajada />
            </Box>
          </Grid>
          {sectionTitleLinks(productsURLs, productsState.loading).map(
            ({ title, links, linksLoading }) => (
              <Grid
                key={title}
                item
                container
                direction="column"
                alignItems="center"
                xs={12}
                md={4}
              >
                <Box className={classes.boxLinks}>
                  <NeoforniLinksTabs
                    title={title}
                    links={links}
                    linksLoading={linksLoading}
                    tabsProps={{
                      scrollButtons: 'desktop',
                      orientation: 'vertical',
                      variant: 'scrollable',
                    }}
                  />
                </Box>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Divider />
            <Box display="flex" alignItems="center">
              <Typography variant="caption">
                Copyright © 2021 Neoforni. All Rights Reserved.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </NeoforniContainer>
    </>
  );
};

export default Footer;
