import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FirebaseAdminContext } from '../../Admin/FirebaseAdminContext';

const ButtonNewItem = ({ title, ...props }) => {
  const [adminLogged] = useContext(FirebaseAdminContext);
  const theme = useTheme();

  if (!adminLogged) return null;
  return (
    title ? (
      <Tooltip title={title} placement="top" arrow>
        <IconButton
          color="secondary"
          style={{ zIndex: theme.appBar - 1 }}
          {...props}
        >
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        color="secondary"
        style={{ zIndex: theme.appBar - 1 }}
        {...props}
      >
        <AddCircleIcon />
      </IconButton>
    )
  );
};

ButtonNewItem.propTypes = {
  title: PropTypes.string,
};

export default ButtonNewItem;
