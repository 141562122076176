import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins',
    h4: {
      fontSize: '1.8rem',
      fontWeight: 800,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
    h5: {
      fontWeight: 800,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
    },
    h6: {
      fontWeight: 700,
      fontSize: '1rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          '& ::selection': {
            background: 'rgba(204, 153, 102, 0.5)',
          },
        },
        body: {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          minHeight: '100vh',
        },
        '&:#root': {
          height: 'inherit',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  palette: {
    primary: {
      main: 'rgb(7, 165, 81)',
    },
    secondary: {
      main: '#c96',
    },
  },
  zIndex: {
    appBar: 5000,
    modal: 4999,
    tooltip: 5001,
  },
});

const Palette = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

Palette.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Palette;
