import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import FieldText from '../shared/Components/Form/FieldText';
import FormAlert from '../shared/Components/Form/FormAlert';
import FormSubmitButton from '../shared/Components/Form/FormSubmitButton';
import FormGridContainer from '../shared/Components/Form/FormGridContainer';
import { useNeoforniForm } from '../shared/Components/Form/useNeoforniForm';

const HablemosEmailFormPropTypes = {
  handleAddUpdate: PropTypes.func.isRequired,
};

const HablemosEmailForm = ({
  handleAddUpdate,
}) => {
  const form = useNeoforniForm({
    defaultValues: {
      from_name: '',
      subject: '',
      body: '',
    },
    mode: 'onBlur',
  });

  const handleSubmit = async (values) => {
    try {
      const response = await handleAddUpdate(values);
      return response;
    } catch (error) {
      form.setErrorSubmit('Error with email service');
      console.error(error);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        id="hablemos_email_form"
        name="hablemos_email_form"
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
      >
        <FormGridContainer spacing={2}>
          <FieldText
            name="from_name"
            label="Nombre y Apellido"
            placeholder="Carlos Ramirez"
          />
          <FieldText
            name="subject"
            label="Asunto"
            placeholder="Información sobre los productos"
          />
          <FieldText
            name="body"
            label="Mensaje"
            placeholder="Hola!, quería obtener información sobre sus productos"
            multiline
            rows={10}
          />
          <FormSubmitButton
            label="Enviar"
            isSubmitting={form.isSubmitting}
            endIcon={!form.isSubmitting && <ArrowForwardIcon />}
            style={{ color: '#fafafa', width: '300px', height: '40px' }}
          />
          <FormAlert isSubmitting={form.isSubmitting}>{form.errorSubmit}</FormAlert>
        </FormGridContainer>
      </form>
    </FormProvider>
  );
};

HablemosEmailForm.propTypes = HablemosEmailFormPropTypes;

export default HablemosEmailForm;
