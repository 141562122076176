import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const FormGridItem = ({ children, noWrap, ...props }) => {
  return (
    noWrap ? (
      children
    ) : (
      <Grid item xs={12} {...props}>
        {children}
      </Grid>
    )
  );
};

const IFormGridItemPropTypes = {
  children: PropTypes.node.isRequired,
  gridProps: PropTypes.object,
  noWrap: PropTypes.bool,
};
const { children, ...FormGridItemPropTypes } = IFormGridItemPropTypes;

FormGridItem.propTypes = IFormGridItemPropTypes;

export default FormGridItem;
export { FormGridItemPropTypes };
