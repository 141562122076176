import { isNotEmpty } from '../tools';

const formDefaultValues = (product = {}) => {
  const {
    name = '',
    subtitle = '',
    description = '',
    features = '',
    // eslint-disable-next-line camelcase
    technical_image = '',
    images_big: imagesBig = [],
    images_small: imagesSmall = [],
    video = '',
    accesories = [{ name: '', description: '', image: '' }],
  } = product;
  return {
    name,
    subtitle,
    description,
    features,
    technical_image,
    images_big: isNotEmpty(imagesBig) ? imagesBig.map((image) => ({ image })) : [{ image: '' }],
    images_small: isNotEmpty(imagesSmall) ? imagesSmall.map((image) => ({ image })) : [{ image: '' }],
    video,
    accesories: isNotEmpty(accesories) ? accesories : [{ name: '', description: '', image: '' }],
  };
};

const formValuesToApi = (productFormValues = {}) => {
  // eslint-disable-next-line camelcase
  const { images_big = [] } = productFormValues;
  return {
    ...productFormValues,
    images_big: images_big.map(({ image }) => image),
    images_small: images_big.map(({ image }) => image),
  };
};

const getErrorFieldArrayByName = ({ form, fieldArrayName, fieldName, idx }) => {
  if (!form.errors[fieldArrayName]) return '';

  return form.errors[fieldArrayName][idx][fieldName].message;
};

export {
  formDefaultValues,
  formValuesToApi,
  getErrorFieldArrayByName,
};
