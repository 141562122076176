import { useForm } from 'react-hook-form';

const SUBMIT_PSEUDO_INPUT_NAME = 'SUBMIT_PSEUDO_INPUT';

const clearSubmitErrorPriv = (clearErrors) => { if (clearErrors) clearErrors(SUBMIT_PSEUDO_INPUT_NAME); };

const setSubmitErrorPriv = (setError, message) => { if (setError) setError(SUBMIT_PSEUDO_INPUT_NAME, { message }); };

const getSubmitErrorPriv = (errors) => errors?.[SUBMIT_PSEUDO_INPUT_NAME]?.message;

// igual que useForm , pero devuelve tambien:
// setErrorSubmit(string), errorSubmit (para manejar errores en submit)
// y tambien explicitamente: isSubmitting, isDirty, errors (del formState)
// https://react-hook-form.com/api/useform

export const useNeoforniForm = ({
  mode = 'onSubmit',
  reValidateMode = 'onChange',
  defaultValues = {},
  resolver = undefined,
  context = undefined,
  criteriaMode = 'firstError',
  shouldFocusError = true,
  shouldUnregister = false,
  ...rest // se pasa a useForm
}) => {
  const form = useForm({
    mode,
    reValidateMode,
    defaultValues,
    resolver,
    context,
    criteriaMode,
    shouldFocusError,
    shouldUnregister,
    ...rest,
  });
  const { setError, clearErrors, formState } = form;
  const { isSubmitting, isDirty, errors } = formState;
  const setErrorSubmit = (msg) => { // string vacio es tratado como undefined
    if (msg) setSubmitErrorPriv(setError, msg);
    else clearSubmitErrorPriv(clearErrors);
  };
  const errorSubmit = getSubmitErrorPriv(errors);
  return {
    isSubmitting, isDirty, errors, setErrorSubmit, errorSubmit, ...form,
  };
};
