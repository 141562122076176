import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import DangerFormAlert from '../shared/Components/DangerFormAlert';
import { FormProvider } from 'react-hook-form';
import FieldText from '../shared/Components/Form/FieldText';
import FieldRichText from '../shared/Components/Form/FieldRichText';
import FormGridItem from '../shared/Components/Form/FormGridItem';
import FormGridContainer from '../shared/Components/Form/FormGridContainer';
import FormAlert from '../shared/Components/Form/FormAlert';
import { useNeoforniForm } from '../shared/Components/Form/useNeoforniForm';

const NosotrosForm = ({ nosotros, handleUpdate }) => {
  const { fid, title, description, image } = nosotros;
  const defaultValues = {
    fid,
    title,
    description,
    image,
  };
  const form = useNeoforniForm({
    defaultValues,
    mode: 'onBlur',
  });

  const handleSubmit = async (values) => {
    try {
      const response = await handleUpdate(values);
      return response;
    } catch (error) {
      form.setErrorSubmit(error);
      console.error(error);
    }
  };

  if (!nosotros) return null;
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        name="nosotros_form"
        id="nosotros_form"
        noValidate
        data-testid="nosotros_form"
      >
        <FormGridContainer>
          <FormGridItem>
            <Typography variant="h5" gutterBottom>
              Actualizar Formulario
            </Typography>
          </FormGridItem>
          <FieldText
            name="title"
            label="Title"
          />
          <FieldRichText
            name="description"
            label="Description"
          />
          <FieldText
            name="image"
            label="Imagen"
          />
          <FormAlert>{form.errorSubmit?.message}</FormAlert>
          <FormGridItem>
            <DangerFormAlert
              isSubmitting={form.isSubmitting}
            />
          </FormGridItem>
        </FormGridContainer>
      </form>
    </FormProvider>
  );
};

NosotrosForm.propTypes = {
  nosotros: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func,
};

export default NosotrosForm;
