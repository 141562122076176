import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';
import { getProductPath, sortProducts } from './productosHelper';
import { devconsole, historyPushSlugify, useBreakpoint, usePromiseToState } from '../tools';
import { useHistory } from 'react-router';
import DraggableProducts from './DraggableProducts';
import CardPresentationShadow from '../shared/Components/CardPresentationShadow';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import AddProducto from './AddProducto';
import { getCollectionData, updateItems } from '../firebase';
import ButtonSortItems from '../shared/Components/ButtonSortItems';

const Products = () => {
  const [productsState, setProductsState, reloadPage] = usePromiseToState({
    promise: () => getCollectionData('productos'),
  });
  const breakpoint = useBreakpoint();
  const [showDraggable, setShowDraggable] = useState(false);
  const history = useHistory();

  const handleClickProduct = (product) => {
    historyPushSlugify(getProductPath(product), history);
  };

  const handleClickOrdenarProductos = () => {
    setShowDraggable((prevState) => !prevState);
  };

  const handleChangeDragPosition = async (productsSorted) => {
    const newProductsSorted = productsSorted.map((product, idx) => ({ ...product, position: idx }));
    setProductsState((prevState) => ({ ...prevState, loading: true }));
    try {
      await updateItems(newProductsSorted, 'productos');
      setProductsState((prevState) => ({ ...prevState, loading: false }));
      setShowDraggable(false);
      reloadPage();
    } catch (error) {
      devconsole.log('Items could not be re-organized', error);
      setProductsState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  return (
    <NeoforniContainer maxWidth="md" center>
      <LoadingErrorWrapper spinner {...productsState}>
        <Grid container spacing={4}>
          {breakpoint.value >= 3 && (
            <Grid item container direction="row" alignItems="center" justify="flex-end" xs={12}>
              <ButtonSortItems
                title="Ordenar productos"
                onClick={handleClickOrdenarProductos}
              />
              <AddProducto reloadPage={reloadPage} />
            </Grid>
          )}
          {breakpoint.value >= 3 && showDraggable && (
            <Grid item xs={12}>
              <DraggableProducts
                items={sortProducts(productsState.data)}
                handleChangeDragPosition={handleChangeDragPosition}
                boxesPerRow={3}
              >
                {({ item: product }) => (
                  <CardPresentationShadow
                    title={product.name}
                    image={product.images_big[0]}
                  />
                )}
              </DraggableProducts>
            </Grid>
          )}
          {!showDraggable && (
            sortProducts(productsState.data).map((product, idx) => (
              <Grid
                item
                container
                key={product.fid}
                xs={12}
                sm={6}
                md={4}
                justify="center"
                alignItems="center"
              >
                <CardPresentationShadow
                  title={product.name}
                  image={product.images_big[0]}
                  onClick={() => handleClickProduct(product)}
                />
              </Grid>
            ))
          )}
        </Grid>
      </LoadingErrorWrapper>
    </NeoforniContainer>
  );
};

export default Products;
