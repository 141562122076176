import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import NeoforniModal from '../shared/Components/NeoforniModal';
import CardPresentationShadow from '../shared/Components/CardPresentationShadow';

const Video = (props) => (
  <iframe width="600px" height="600px" {...props}></iframe>
);

const ViewVideosPropTypes = {
  videos: PropTypes.array.isRequired,
};

const ViewVideos = ({ videos }) => {
  const theme = useTheme();
  const [videoSelected, setVideoSelected] = useState(null);
  const modalRef = useRef();

  const handleClickVideoLink = (idx) => {
    modalRef.current.handleToggleModal();
    setVideoSelected(videos[idx].video);
  };

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} justify="center">
        <Typography
          variant="h5"
          style={{ marginBottom: theme.spacing(3) }}
        >
          Videos y Tutoriales
        </Typography>
      </Grid>
      {videos.map((video, idx) => (
        <Grid
          item
          container
          key={video.title}
          xs={12}
          sm={6}
          md={4}
          justify="center"
          alignItems="center"
        >
          <CardPresentationShadow
            title={video.title}
            image={video.image_preview}
            onClick={() => handleClickVideoLink(idx)}
          />
        </Grid>
      ))}
      <NeoforniModal ref={modalRef} noWrapChildren>
        <Video src={videoSelected} />
      </NeoforniModal>
    </Grid>
  );
};

ViewVideos.propTypes = ViewVideosPropTypes;

export default ViewVideos;
