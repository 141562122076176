import { useRef, useState } from 'react';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteItem } from '../firebase';
import NeoforniModal from '../shared/Components/NeoforniModal';
import { useSnackbar } from 'notistack';
import { Button, useTheme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Security from '@material-ui/icons/Security';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const DeleteDocumentPropTypes = {
  document: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  reloadPage: PropTypes.func.isRequired,
};

const DeleteDocument = ({ document, label, collection, reloadPage }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deletingState, setDeletingState] = useState({ loading: false, error: '' });
  const modalRef = useRef();

  const handleToggleModal = () => modalRef.current.handleToggleModal();
  const handleDeleteDocument = async () => {
    setDeletingState({ loading: true, error: '' });
    try {
      const response = await deleteItem(document.fid, collection);
      enqueueSnackbar('Documento borrado exitosamente', { variant: 'success' });
      setDeletingState({ loading: false, error: '' });
      handleToggleModal();
      reloadPage();
    } catch (error) {
      setDeletingState({ loading: false, error: '' });
      enqueueSnackbar('El documento no se ha podido eliminar', { variant: 'error' });
      handleToggleModal();
      console.error('Error deleting document from panel', error);
    }
  };

  if (!document) return null;

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {label}
        <IconButton onClick={handleToggleModal}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <DeleteDocumentModal
        handleDeleteDocument={handleDeleteDocument}
        deletingState={deletingState}
        modalRef={modalRef}
      >
        <Alert severity="error">
          <AlertTitle>Atencion!</AlertTitle>
          Estás por borrar el documento
          <b> {label} </b>
          de la colección
          <b> {collection}</b>
        </Alert>
      </DeleteDocumentModal>
    </>
  );
};

const DeleteDocumentModalPropTypes = {
  handleDeleteDocument: PropTypes.func.isRequired,
  deletingState: PropTypes.shape({
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node,
  modalRef: PropTypes.any,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: '#fafafa',
  },
}));

const DeleteDocumentModal = ({
  handleDeleteDocument,
  children,
  modalRef,
  deletingState,
  ...props
}) => {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState({
    isValid: false,
    validating: false,
    error: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleShowPassword = () => setShowPassword((prev) => !prev);
  const handleChangePassword = async (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handleClickValidatePassword = async () => {
    setPasswordValidation({ ...passwordValidation, validating: true });
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword('neoforni@gmail.com', password);
      setPasswordValidation({ isValid: true, validating: false, error: '' });
    } catch (e) {
      setPasswordValidation({
        isValid: false,
        validating: false,
        error: e.message,
      });
      console.error(e);
    }
  };

  return (
    <NeoforniModal
      title="Borrar documento"
      enableButtons
      labelConfirm={deletingState.loading ? 'Borrando...' : 'Borrar'}
      labelCancel="Cerrar"
      ref={modalRef}
      buttonConfirmProps={{
        variant: 'contained',
        error: true,
        classes: {
          root: classes.root,
        },
        disabled: !passwordValidation.isValid,
        startIcon: !deletingState.loading && <DeleteIcon />,
        onClick: handleDeleteDocument,
      }}
      {...props}
    >
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        spacing={4}
      >
        <Grid item xs={12}>
          <Typography variant="bod1" gutterBottom>
            {children}
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={4}
          justify="space-around"
          alignItems="center"
          direction="column"
          xs={12}
        >
          <Grid item xs={12} style={{ width: '100%' }}>
            <TextField
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              label="Ingresá tu contraseña"
              value={password}
              onChange={handleChangePassword}
              error={!!passwordValidation.error}
              helperText={passwordValidation.error}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleClickValidatePassword}
              variant="contained"
              disableElevation
              color="primary"
              size="large"
              startIcon={<Security />}
            >
              {passwordValidation.validating
                ? 'Validando...'
                : 'Validar contraseña'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </NeoforniModal>
  );
};

DeleteDocument.propTypes = DeleteDocumentPropTypes;
DeleteDocumentModal.propTypes = DeleteDocumentModalPropTypes;

export default DeleteDocument;
