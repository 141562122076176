import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import NeoforniModal from '../shared/Components/NeoforniModal';
import RecetaForm from './RecetaForm';
import { addReceta } from './Receta/recetaHelper';
import ButtonNewItem from '../shared/Components/ButtonNewItem';

const AddRecetaPropTypes = {
  reloadPage: PropTypes.func.isRequired,
};

const AddReceta = ({ reloadPage }) => {
  const modalRef = useRef();
  const handleAddReceta = async (formValues) => {
    try {
      const response = await addReceta(formValues);
      reloadPage();
      modalRef.current.handleToggleModal();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    };
  };

  const handleClickNewReceta = () => {
    modalRef.current.handleToggleModal();
  };

  return (
    <>
      <ButtonNewItem
        style={{ position: 'relative', float: 'right' }}
        onClick={handleClickNewReceta}
      >
        Agregar nueva receta
      </ButtonNewItem>
      <NeoforniModal title="Nueva receta" ref={modalRef}>
        <RecetaForm handleAddUpdate={handleAddReceta} />
      </NeoforniModal>
    </>
  );
};

AddReceta.propTypes = AddRecetaPropTypes;

export default AddReceta;
