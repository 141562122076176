import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import FormGridItem, { FormGridItemPropTypes } from './FormGridItem';

const FormAlert = ({ children, gridProps, isSubmitting, ...props }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (children) setOpen(true);
  }, [children]);

  if (!open || isSubmitting) return null;
  return (
    <FormGridItem {...gridProps}>
      <Alert
        color="error"
        severity="error"
        variant="filled"
        onClose={() => setOpen(false)}
        {...props}
      >
        {children}
      </Alert>
    </FormGridItem>
  );
};

FormAlert.propTypes = {
  children: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  ...FormGridItemPropTypes,
};

export default FormAlert;
