import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import DangerFormAlert from '../shared/Components/DangerFormAlert';
import FormAlert from '../shared/Components/Form/FormAlert';
import FormGridContainer from '../shared/Components/Form/FormGridContainer';
import FormGridItem from '../shared/Components/Form/FormGridItem';
import FieldText from '../shared/Components/Form/FieldText';
import FieldTextImagePreview from '../shared/Components/Form/FieldTextImagePreview';
import { buildFieldNameFieldArray } from '../shared/Components/Form/formTools';
import { isEmpty } from '../tools';
import { useNeoforniForm } from '../shared/Components/Form/useNeoforniForm';
import { formValuesToApi } from './videosHelper';

const VIDEOS_FIELD_ARRAY_NAME = 'videos';

const VideosFormPropTypes = {
  videos: PropTypes.array.isRequired,
  handleAddUpdate: PropTypes.func.isRequired,
};

const VideosForm = ({ videos, handleAddUpdate }) => {
  const isAddNewVideoForm = isEmpty(videos);
  const defaultValues = isAddNewVideoForm ? {
    [VIDEOS_FIELD_ARRAY_NAME]: [{ title: '', video: '', image_preview: '' }],
  } : {
    [VIDEOS_FIELD_ARRAY_NAME]: videos,
  };

  const validationSchema = yup.object().shape({
    [VIDEOS_FIELD_ARRAY_NAME]: yup
      .array()
      .of(
        yup.object().shape({
          title: yup.string().required('Campo requerido'),
          video: yup.string().required('Campo requerido'),
          image_preview: yup.string().required('Campo requerido'),
        }),
      ),
  });

  const form = useNeoforniForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (formValues) => {
    try {
      const newFormValues = formValuesToApi(formValues);
      const response = await handleAddUpdate(newFormValues);
      return response;
    } catch (error) {
      form.setErrorSubmit(error);
      console.error(error);
    };
  };

  return (
    <FormProvider {...form}>
      <form noValidate id="videos_form" name="videos_form" onSubmit={form.handleSubmit(handleSubmit)}>
        <FormGridContainer spacing={4}>
          <FieldArrayVideos isAddNewVideoForm={isAddNewVideoForm} />
          <FormGridItem>
            <DangerFormAlert isSubmitting={form.isSubmitting} isAddNewVideoForm={isAddNewVideoForm} />
          </FormGridItem>
          <FormAlert isSubmitting={form.isSubmitting}>{form.errorSubmit?.message}</FormAlert>
        </FormGridContainer>
      </form>
    </FormProvider>
  );
};

const FieldArrayVideosPropTypes = {
  isAddNewVideoForm: PropTypes.bool,
};

const FieldArrayVideos = ({ isAddNewVideoForm }) => {
  const form = useFormContext();
  const { fields } = useFieldArray({
    control: form.control,
    name: VIDEOS_FIELD_ARRAY_NAME,
  });

  const getErrorField = (idx, fieldName) => {
    const errorField = form.errors?.[VIDEOS_FIELD_ARRAY_NAME] ? form.errors?.[VIDEOS_FIELD_ARRAY_NAME][idx]?.[fieldName]?.message : '';
    return errorField;
  };

  return (
    fields.map((item, idx) => (
      <FormGridItem container key={item.id}>
        <FormGridItem container xs={12} spacing={2}>
          <FieldText
            name={buildFieldNameFieldArray(VIDEOS_FIELD_ARRAY_NAME, 'title', idx)}
            label="Título"
            gridProps={{ xs: 12 }}
            error={!!getErrorField(idx, 'title')}
            helperText={getErrorField(idx, 'title')}
          />
          {!isAddNewVideoForm && (
            <FieldText
              name={buildFieldNameFieldArray(VIDEOS_FIELD_ARRAY_NAME, 'fid', idx)}
              style={{ display: 'none' }}
            />
          )}
          <FieldText
            name={buildFieldNameFieldArray(VIDEOS_FIELD_ARRAY_NAME, 'video', idx)}
            label="Link del video"
            gridProps={{ xs: 6 }}
            multiline
            rows={10}
            error={!!getErrorField(idx, 'video')}
            helperText={getErrorField(idx, 'video')}
          />
          <FieldTextImagePreview
            name={buildFieldNameFieldArray(VIDEOS_FIELD_ARRAY_NAME, 'image_preview', idx)}
            label="Portada"
            gridProps={{ xs: 6 }}
            multiline
            rows={10}
            error={!!getErrorField(idx, 'image_preview')}
            helperText={getErrorField(idx, 'image_preview')}
          />
        </FormGridItem>
      </FormGridItem>
    ))
  );
};

FieldArrayVideos.propTypes = FieldArrayVideosPropTypes;
VideosForm.propTypes = VideosFormPropTypes;

export default VideosForm;
