import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import WRoute from './WrapLayoutRoute';
import TalleresCursos from '../TalleresCursos';
import Recetas from '../Recetas';
import Receta from '../Recetas/Receta';
import Home from '../Home';
import Tips from '../Tips';
import Admin from '../Admin';
import Panel from '../Panel';
import Productos from '../Productos';
import NeoforniHeader from '../shared/Components/NeoforniHeader';
import Nosotros from '../Nosotros';
import Developers from '../Developers';
import Contactanos from '../Contactanos';
import Videos from '../Videos';
import ViewProduct from '../Productos/ViewProduct';

const App = () => {
  return (
    <Router>
      <Switch>
        <WRoute
          exact
          path="/nosotros"
          title="Nosotros"
          LayoutProps={{
            Header: <NeoforniHeader title="Sobre Neoforni" />,
          }}
        >
          <Nosotros />
        </WRoute>
        <WRoute
          exact
          path="/productos"
          title="Productos"
          LayoutProps={{
            Header: <NeoforniHeader title="Productos" />,
          }}
        >
          <Productos />
        </WRoute>
        <WRoute exact path="/productos/:product/:id" title="Productos">
          <ViewProduct />
        </WRoute>
        <WRoute
          exact
          path="/talleres-y-cursos"
          title="Talleres y cursos"
          LayoutProps={{
            Header: <NeoforniHeader title="Cursos y talleres" />,
          }}
        >
          <TalleresCursos />
        </WRoute>
        <WRoute
          exact
          path="/recetas"
          title="Recetas"
          LayoutProps={{
            Header: <NeoforniHeader title="Recetas Neoforni" />,
          }}
        >
          <Recetas />
        </WRoute>
        <WRoute
          path="/recetas/:receta/:id"
          title="Recetas"
          LayoutProps={{ Header: <NeoforniHeader title="Recetas Neoforni" /> }}
        >
          <Receta />
        </WRoute>
        <WRoute
          title="Tips"
          path="/tips"
          LayoutProps={{
            Header: <NeoforniHeader title="Preguntas Frecuentes" />,
          }}
        >
          <Tips />
        </WRoute>
        <WRoute path="/videos" title="Videos">
          <Videos />
        </WRoute>
        <WRoute
          path="/contactanos"
          title="Contactanos"
          LayoutProps={{
            Header: (
              <NeoforniHeader title="Contactanos" subtitle="...y conocenos" />
            ),
          }}
        >
          <Contactanos />
        </WRoute>
        <WRoute path="/admin" LayoutProps={{ omitFooter: true }} title="Admin">
          <Admin />
        </WRoute>
        <WRoute path="/configuracion" LayoutProps={{ omitFooter: true }} title="Configuración" adminRoute>
          <Panel />
        </WRoute>
        <WRoute path="/dev" adminRoute>
          <Developers />
        </WRoute>
        <WRoute
          path="/"
          LayoutProps={{
            noPaddingContent: true,
          }}
        >
          <Home />
        </WRoute>
      </Switch>
    </Router>
  );
};

export default App;
