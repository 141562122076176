import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import TipsForm from './TipsForm';
import { addTip } from './tipsHelper';
import NeoforniBreadcrumbs from '../shared/Components/NeoforniBreadcrumbs';
import ButtonNewItem from '../shared/Components/ButtonNewItem';
import NeoforniModal from '../shared/Components/NeoforniModal';

const AddTipPropTypes = {
  reloadPage: PropTypes.func.isRequired,
};

const AddTip = ({ reloadPage }) => {
  const modalRef = useRef();

  const handleAdd = async (formValues) => {
    try {
      const response = await addTip(formValues);
      reloadPage();
      modalRef.current.handleToggleModal();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <>
      <Box width="100%" display="flex" justifyContent="space-between" mb={2}>
        <NeoforniBreadcrumbs style={{ marginBottom: 0 }} />
        <ButtonNewItem onClick={() => modalRef.current.handleToggleModal()}>
          Agregar nueva F.A.Q.
        </ButtonNewItem>
      </Box>
      <NeoforniModal ref={modalRef} title="Nueva F.A.Q." maxWidth="sm">
        <TipsForm handleAddUpdate={handleAdd} />
      </NeoforniModal>
    </>
  );
};

AddTip.propTypes = AddTipPropTypes;

export default AddTip;
