import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Productos from '../Productos';
import RecetasCarousel from '../Recetas/Receta/RecetaVerMas';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    padding: '40px 0',
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <video autoPlay width="100%" muted loop playsInline>
        <source src="https://neoforni.com.ar/videos/home_video.mp4" type="video/mp4" />
      </video>
      <NeoforniContainer maxWidth="lg" classes={classes}>
        <Grid container spacing={4}>
          <Grid item container xs={12} justify="center">
            <Typography variant="h5">Productos</Typography>
          </Grid>
          <Grid item xs={12}>
            <Productos />
          </Grid>
          <Grid item container xs={12} justify="center" alignItems="center">
            <Typography variant="h5">Recetas</Typography>
          </Grid>
          <Grid item container xs={12} justify="center">
            <RecetasCarousel />
          </Grid>
        </Grid>
      </NeoforniContainer>
    </>
  );
};

export default Home;
