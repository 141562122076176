import { useRef } from 'react';
import CursoTitleDescription from './CursoTitleDescription';
import UpdateTallerCurso from './UpdateTallerCurso';

const ViewTalleresCursos = ({ talleresCursos, reloadPage }) => {
  return talleresCursos.map((tallerCurso) => (
    <UpdateTallerCurso
      key={tallerCurso.name}
      tallerCurso={tallerCurso}
      reloadPage={reloadPage}
    >
      <CursoTitleDescription
        tallerCurso={tallerCurso}
        reloadPage={reloadPage}
      />
    </UpdateTallerCurso>
  ));
};

export default ViewTalleresCursos;
