import PropTypes from 'prop-types';
import * as yup from 'yup';
import { FormProvider, useWatch } from 'react-hook-form';
import DangerFormAlert from '../shared/Components/DangerFormAlert';
import Typography from '@material-ui/core/Typography';
import FormGridItem from '../shared/Components/Form/FormGridItem';
import FieldText from '../shared/Components/Form/FieldText';
import FieldSelect from '../shared/Components/Form/FieldSelect';
import { useNeoforniForm } from '../shared/Components/Form/useNeoforniForm';
import FormAlert from '../shared/Components/Form/FormAlert';
import FormGridContainer from '../shared/Components/Form/FormGridContainer';
import { isEmpty } from '../tools';
import FieldRichText from '../shared/Components/Form/FieldRichText';
import FieldTextImagePreview from '../shared/Components/Form/FieldTextImagePreview';
import { yupResolver } from '@hookform/resolvers';
import { prepareToJson } from './talleresCursosHelper';

const CursoTitleDescriptionForm = ({ tallerCurso, handleAddUpdate }) => {
  const isAddNewItem = isEmpty(tallerCurso);
  const defaultValues = isAddNewItem ? {
    name: '',
    description: '',
    media_type: 'image',
    image: '',
    video: '',
  } : {
    name: tallerCurso.name,
    description: tallerCurso.description,
    media_type: tallerCurso.image ? 'image' : 'video',
    image: tallerCurso.image,
    video: tallerCurso.video,
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Campo requerido')
      .max(30, 'El campo puede tener como máximo 30 caracteres'),
    description: yup
      .string()
      .required('Campo requerido')
      .min(20, 'El campo debe tener como mínimo 20 caracteres'),
    media_type: yup
      .string()
      .required('Campo requerido'),
    image: yup
      .string()
      .when('media_type', {
        is: 'image',
        then: yup.string().required('Campo requerido'),
      }),
    video: yup
      .string()
      .when('media_type', {
        is: 'video',
        then: yup.string().required('Campo requerido'),
      }),
  });

  const form = useNeoforniForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const mediaTypeField = useWatch({
    name: 'media_type',
    control: form.control,
    defaultValue: defaultValues.media_type,
  });

  const handleSubmit = async (values) => {
    try {
      const response = await handleAddUpdate(prepareToJson(values));
      return response;
    } catch (error) {
      form.setErrorSubmit(error);
      console.error(error);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        name="curso_title_description_form"
        id="curso_title_description_form"
        noValidate
        data-testid="curso_title_description_form"
      >
        <FormGridContainer>
          {!isAddNewItem && (
            <FormGridItem>
              <Typography variant="h5" gutterBottom>Actualizar curso</Typography>
            </FormGridItem>
          )}
          <FieldText name="name" label="Curso" />
          <FieldRichText
            name="description"
            label="Descripción"
          />
          <FieldSelect
            name="media_type"
            label="Tipo de archivo"
            options={[
              { value: 'image', label: 'Imagen' },
              { value: 'video', label: 'Video' },
            ]}
          />
          {mediaTypeField === 'image' ? (
            <FieldTextImagePreview
              name="image"
              label="Link de la imagen"
            />
          ) : (
            <FieldText
              name="video"
              label="Link del video"
            />
          )}
          <FormGridItem>
            <DangerFormAlert isSubmitting={form.isSubmitting} isAddNewItem={isAddNewItem} />
          </FormGridItem>
          <FormAlert isSubmitting={form.isSubmitting}>{form.errorSubmit?.message}</FormAlert>
        </FormGridContainer>
      </form>
    </FormProvider>
  );
};

CursoTitleDescriptionForm.propTypes = {
  tallerCurso: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    media_type: PropTypes.oneOf(['image', 'video']),
    image: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    fid: PropTypes.string.isRequired,
  }),
  handleAddUpdate: PropTypes.func.isRequired,
};

export default CursoTitleDescriptionForm;
