import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import StoreIcon from '@material-ui/icons/Store';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import InnerImageZoom from 'react-inner-image-zoom';
import Carousel from 'react-material-ui-carousel';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { useSnackbar } from 'notistack';
import NeoforniCard from '../../shared/Containers/NeoforniCard';
import NeoforniModal from '../../shared/Components/NeoforniModal';
import ShowMarkUpText from '../../shared/Components/ShowMarkUpText';
import SolicitarPresupuestoForm from './SolicitarPresupuestoForm';
import { WHATSAPP_API, buildWhatsAppPreMessage } from '../../shared/global';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'transparent',
  },
}));

const TitleDescriptionPropTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imagesBig: PropTypes.array.isRequired,
};

const TitleDescription = ({
  name,
  subtitle,
  description,
  imagesBig,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const presupuestoModalRef = useRef();

  const handleClickSolicitarPresupuestoButton = () => {
    presupuestoModalRef.current.handleToggleModal();
  };

  const handleAddUpdate = async (values) => {
    const text = buildWhatsAppPreMessage(values);
    const url = `${WHATSAPP_API}&text=${text}`;
    const newWindow = window.open(url, 'Whatsapp');
    newWindow?.focus();
  };

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems="center" justify="center" xs={12} md={6}>
        <Carousel
          autoPlay={false}
          stopAutoPlayOnHover
          animation="slide"
          timeout={600}
        >
          {imagesBig.map((image) => {
            return (
              <Box key={image}>
                <InnerImageZoom
                  src={image}
                  srcSet={`${image} 2x`}
                  zoomType="hover"
                />
              </Box>
            );
          })}
        </Carousel>
      </Grid>
      <Grid item container xs={12} md={6}>
        <Grid item xs={12}>
          <NeoforniCard
            title={name}
            subtitle={subtitle}
            classes={{
              root: classes.paper,
            }}
          >
            <ShowMarkUpText text={description} />
          </NeoforniCard>
        </Grid>
        <Grid item container xs={12} justify="flex-start" alignItems="flex-end">
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            style={{ width: '220px', height: '50px', color: 'white', borderRadius: '50px' }}
            startIcon={<StoreIcon />}
            onClick={handleClickSolicitarPresupuestoButton}
          >
            Solicitar presupuesto
          </Button>
        </Grid>
        <NeoforniModal
          title="Solicitá tu presupuesto"
          ref={presupuestoModalRef}
          maxWidth="sm"
        >
          <SolicitarPresupuestoForm
            productName={name}
            handleAddUpdate={handleAddUpdate}
          />
        </NeoforniModal>
      </Grid>
    </Grid>
  );
};

TitleDescription.propTypes = TitleDescriptionPropTypes;

export default TitleDescription;
