import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white',
    borderColor: 'white',
    borderRadius: 0,
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  typography: {
    color: 'white',
  },
  container: {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/neoforni-web-app-d3d05.appspot.com/o/background_2.jpeg?alt=media&token=3cb69310-9556-4b5e-8a5f-ca7cea480afc)',
    width: '100%',
    margin: '20px 0px',
    padding: theme.spacing(2),
    // height: '128px',
  },
}));

const TenesMasDudasContactanos = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickContactanos = () => {
    history.push('/contactanos');
  };

  return (
    <Grid container spacing={4} className={classes.container} {...props}>
      <Grid item container xs={12} sm={6} alignItems="center" justify="center">
        <Typography variant="h4" className={classes.typography}>¿Tenés más dudas?</Typography>
      </Grid>
      <Grid item container xs={12} sm={6} alignItems="center" justify="center">
        <Button variant="outlined" className={classes.button} endIcon={<ArrowForwardIcon />} onClick={handleClickContactanos}>
          Contactanos
        </Button>
      </Grid>
    </Grid>
  );
};

TenesMasDudasContactanos.propTypes = {
};

export default TenesMasDudasContactanos;
