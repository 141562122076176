import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import NeoforniLogo from './Logo';
import { makeStyles, useTheme } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Settings from '@material-ui/icons/Settings';
import { slugify, useBreakpoint } from '../tools';
import Footer from './Footer';
import { WhatsApp, Instagram, YouTube, ExpandMore, Chat } from '@material-ui/icons';
import { INSTAGRAM_SOURCE, TEXT_DEFAULT_TO_SEND_WHATSAPP, YOUTUBE_SOURCE } from '../shared/global';
import Zoom from '@material-ui/core/Zoom';

export const LayoutContext = React.createContext(null);

// { label, href } most common cases
// also { isGroup, items } used for LinkTabMenu
const sections = [
  { label: <NeoforniLogo width="80px" />, href: '/home' },
  { label: 'Nosotros', href: '/nosotros' },
  { label: 'Productos', href: '/productos' },
  { label: 'Videos', href: '/videos' },
  { label: 'Tips', href: '/tips' },
  { label: 'Recetas', href: '/recetas' },
  { label: 'Talleres', href: '/talleres_y_cursos' },
  { label: 'Contactanos', href: '/contactanos' },
  { label: <Tooltip title="Configuración"><Settings /></Tooltip>, href: '/configuracion', adminRoute: true },
];

const useStyles = makeStyles((theme) => ({
  instagramFab: {
    background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
    '&:hover': {
      background: 'radial-gradient(circle at 40% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
    },
    color: '#fff',
    position: 'fixed',
    bottom: 120,
    right: 20,
  },
  whatsAppFab: {
    background: 'linear-gradient(180deg, rgba(18,203,0,1) 0%, rgba(36,111,0,1) 100%)',
    '&:hover': {
      background: 'linear-gradient(180deg, rgba(18,203,0,1) 0%, rgba(36,111,0,0.8) 80%)',
    },
    color: '#fff',
    position: 'fixed',
    bottom: 170,
    right: 20,
  },
  youTubeFab: {
    background: 'linear-gradient(180deg, rgba(255,0,0,1) 0%, rgba(40,40,40,1) 100%)',
    '&:hover': {
      background: 'linear-gradient(180deg, rgba(255,0,0,1) 0%, rgba(40,40,40,0.8) 80%)',
    },
    color: '#fff',
    position: 'fixed',
    bottom: 70,
    right: 20,
  },
  defaultGrayFab: {
    background: 'radial-gradient(circle, rgba(146, 150, 146, 1) 0 %, rgba(135, 135, 135, 0.18) 35 %, rgba(75, 75, 75, 1) 100 %)',
    '&:hover': {
      background: 'radial-gradient(circle, rgba(80, 80, 80, 1) 0 %, rgba(255, 255, 255, 0.18) 35 %, rgba(0, 0, 0, 1) 100 %)',
    },
    color: '#000',
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
  defaultPrimaryFab: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    color: '#fff',
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
}));

const Layout = ({
  omitFooter = false,
  children,
  noPaddingContent,
  Header,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [showFABs, setShowFABs] = useState(false);

  useEffect(() => {
    if (breakpoint.value >= 2) {
      setShowMenuIcon(false);
      setOpenSidebar(false);
    } else if (breakpoint.value < 2) setShowMenuIcon(true);
  }, [breakpoint]);

  useEffect(() => {
    sections.forEach((section, idx) => {
      if (section.href && window.location.pathname.includes(slugify(section.href))) {
        setCurrentTab(idx);
      }
    });
  }, [sections, []]);

  const handleClickFAB = (href) => window.open(href);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const toggleShowFABs = () => {
    setShowFABs(!showFABs);
  };

  const FABs = [{
    'aria-label': 'whatsapp-icon',
    href: TEXT_DEFAULT_TO_SEND_WHATSAPP,
    icon: <WhatsApp />,
    className: classes.whatsAppFab,
    transitionDuration: {
      enter: transitionDuration.enter * 3,
      exit: transitionDuration.exit * 1,
    },
  },
  {
    'aria-label': 'instagram-icon',
    href: INSTAGRAM_SOURCE,
    icon: <Instagram />,
    className: classes.instagramFab,
    transitionDuration: {
      enter: transitionDuration.enter * 2,
      exit: transitionDuration.exit * 2,
    },
  },
  {
    'aria-label': 'youtube-icon',
    href: YOUTUBE_SOURCE,
    icon: <YouTube />,
    className: classes.youTubeFab,
    transitionDuration: {
      enter: transitionDuration.enter * 1,
      exit: transitionDuration.exit * 3,
    },
  },
  {
    'aria-label': 'mostrar-redes-icon',
    icon: <Chat />,
    isDefault: true,
    className: classes.defaultPrimaryFab,
    transitionDuration: transitionDuration,
  },
  {
    'aria-label': 'ocultar-redes-icon',
    icon: <ExpandMore />,
    className: classes.defaultGrayFab,
    transitionDuration: transitionDuration,
  }];

  return (
    <LayoutContext.Provider
      value={{
        openSidebar,
        setOpenSidebar,
        showMenuIcon,
        setShowMenuIcon,
        sections,
        currentTab,
      }}
    >
      <Topbar />
      <Sidebar />
      {Header}
      {Header ? (
        children
      ) : (
        <main
          style={noPaddingContent ? {
            marginTop: 48,
          } : {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            marginTop: theme.spacing(2) + 48,
          }}
        >
          {children}
        </main>
      )}
      {!omitFooter && <Footer />}
      {FABs.map((fab) => (
        <Zoom
          key={fab.href}
          in={showFABs === !fab.isDefault}
          timeout={fab.transitionDuration}
          unmountOnExit
        >
          <Fab
            aria-label={fab['aria-label']}
            className={fab.className}
            size="small"
            href={fab.href}
            onClick={(e) => {
              e.preventDefault();
              fab.href ? handleClickFAB(fab.href) : toggleShowFABs();
            }}
          >
            {fab.icon}
          </Fab>
        </Zoom>

      ))}
    </LayoutContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  omitFooter: PropTypes.bool,
  noPaddingContent: PropTypes.bool,
  Header: PropTypes.element,
  GridProps: PropTypes.object,
  GridChildrenProps: PropTypes.object,
  GridContainerProps: PropTypes.object,
  classes: PropTypes.object,
  ContainerProps: PropTypes.object,
  showFABs: PropTypes.bool,
};

export default Layout;
