import { useState, useEffect } from 'react';
import firebase from 'firebase';
import { FirebaseAdminContext } from './Admin/FirebaseAdminContext';
import { SnackbarProvider } from 'notistack';
import Routes from './Router/Routes';
import Palette from './Theme';
import { devconsole } from './tools';

const App = () => {
  const [adminLogged, setAdminLogged] = useState(null);

  useEffect(() => {
    const authObserver = firebase.auth().onAuthStateChanged((user) => {
      devconsole.log(`changing logged admin ${user?.email}`);
      setAdminLogged(user);
    });
    return authObserver;
  });

  return (
    <Palette>
      <SnackbarProvider maxSnack={3}>
        <FirebaseAdminContext.Provider value={[adminLogged, setAdminLogged]}>
          <Routes />
        </FirebaseAdminContext.Provider>
      </SnackbarProvider>
    </Palette>
  );
};

export default App;
