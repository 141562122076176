import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ButtonNewItem from '../shared/Components/ButtonNewItem';
import NeoforniModal from '../shared/Components/NeoforniModal';
import ProductosForm from './ProductosForm';
import { addProduct } from './productosHelper';

const AddProductPropTypes = {
  reloadPage: PropTypes.func.isRequired,
};

const AddProduct = ({ reloadPage }) => {
  const modalRef = useRef();

  const handleClickNewReceta = () => {
    modalRef.current.handleToggleModal();
  };

  const handleAddProduct = async (apiFormValues) => {
    try {
      const response = await addProduct(apiFormValues);
      reloadPage();
      modalRef.current.handleToggleModal();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <>
      <ButtonNewItem onClick={handleClickNewReceta} title="Agregar nuevo producto" />
      <NeoforniModal title="Nuevo producto" ref={modalRef} maxHeight="100%" width="100%">
        <ProductosForm handleAddUpdate={handleAddProduct} />
      </NeoforniModal>
    </>
  );
};

AddProduct.propTypes = AddProductPropTypes;

export default AddProduct;
