import { useState } from 'react';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import NeoforniRichTextEditor from '../NeoforniRichTextEditor';
import { FieldGenericLabelAndHelperText } from './FieldGeneric';

// TODO: abstract the <FormLabel /> <FormHelperText /> and focused logic to a separate <FieldGeneric />
const FieldRichText = ({ name, label, helperText, required, gridProps, ...props }) => {
  const [focused, setFocused] = useState(false);
  const theme = useTheme();

  const handleFocusBlur = () => {
    setFocused(!focused);
  };
  return (
    <FieldGenericLabelAndHelperText name={name} gridProps={gridProps} label={label} helperText={helperText}>
      {({ controller }) => {
        return (
          <NeoforniRichTextEditor
            value={controller.field.value || ''}
            onChange={(e) => controller.field.onChange(e)}
            style={{ marginTop: theme.spacing(1) }}
            onFocus={handleFocusBlur}
            onBlur={handleFocusBlur}
            {...props}
          />
        );
      }}
    </FieldGenericLabelAndHelperText>
  );
};

FieldRichText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  gridProps: PropTypes.object,
  required: PropTypes.bool,
};

export default FieldRichText;
