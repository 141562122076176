/* eslint-disable camelcase */
export const TEXT_DEFAULT_TO_SEND_WHATSAPP = 'https://api.whatsapp.com/send?phone=5491134702394&text=Hola quería saber sobre los hornos que tienen disponibles';
export const WHATSAPP_API = 'https://api.whatsapp.com/send?phone=5491134702394';
export const GOOGLE_MAPS_SOURCE = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.660118577657!2d-58.63150888477549!3d-34.40998388051014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac8632d0a23%3A0x3711744313aae8a4!2sUrban%20Storage%20Nordelta%20-%20Bauleras%20y%20guardamuebles!5e0!3m2!1ses-419!2sar!4v1589678731706!5m2!1ses-419!2sar';
export const INSTAGRAM_SOURCE = 'https://instagram.com/neo.forni';
export const YOUTUBE_SOURCE = 'https://www.youtube.com/neoforni';
export const NAVIGATION_TITLE_DEFAULT = 'Neoforni: La evolución del horno a leña';
export const EMAIL_JS_SERVICE_ID = 'service_8tzc6hm';
export const EMAIL_JS_HABLEMOS_FORM_TEMPLATE_ID = 'template_xgwzo5u';
export const EMAIL_JS_SOLICITAR_PRESUPUESTO_FORM_TEMPLATE_ID = 'template_cotpr7s';
export const EMAIL_JS_USER_ID = 'user_Ho133RAbKmZfScJD9AxeO';

export const buildWhatsAppPreMessage = ({ from_name, subject, body }) => {
  const lines = [
    `Hola mi nombre es *${from_name}*, tengo una consulta sobre *${subject}*`,
    '_El motivo de mi mensaje es:_',
    `%0a*${body}*%0a`,
    'Muchas gracias!',
  ];

  return lines.join('%0a');
};
