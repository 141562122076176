import React from 'react';
import Grid from '@material-ui/core/Grid';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import NeoforniBreadcrumbs from '../shared/Components/NeoforniBreadcrumbs';
import UpdateProducto from './UpdateProducto';
import TitleDescription from './Producto/TitleDescription';
import Caracteristicas from './Producto/Caracteristicas';
import VideoPromocional from './Producto/VideoPromocional';
import Accesorios from './Producto/Accesorios';
import { usePromiseToState } from '../tools';
import { getItem } from '../firebase';
import { useParams } from 'react-router';
import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';

const ViewProduct = () => {
  const { id } = useParams();
  const [productState, , reloadPage] = usePromiseToState({
    promise: () => getItem(id, 'productos'),
    deps: [id],
  });
  const [product] = productState?.data;
  const {
    name = '',
    subtitle = '',
    description = '',
    images_big: imagesBig = [],
    technical_image: technicalImage = '',
    features = '',
    video = '',
    accesories = [],
  } = product || {};

  return (
    <NeoforniContainer maxWidth="lg">
      <LoadingErrorWrapper spinner {...productState}>
        <UpdateProducto product={product} reloadPage={reloadPage}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <NeoforniContainer>
                <NeoforniBreadcrumbs />
                <TitleDescription
                  name={name}
                  subtitle={subtitle}
                  description={description}
                  imagesBig={imagesBig}
                />
              </NeoforniContainer>
            </Grid>
            <Grid item xs={12}>
              <NeoforniContainer center>
                <Caracteristicas technicalImage={technicalImage} features={features} />
              </NeoforniContainer>
            </Grid>
            <Grid item xs={12}>
              <Accesorios accesories={accesories} />
            </Grid>
            <Grid item xs={12}>
              <VideoPromocional video={video} title={name} />
            </Grid>
          </Grid>
        </UpdateProducto>
      </LoadingErrorWrapper>
    </NeoforniContainer>
  );
};

export default ViewProduct;
