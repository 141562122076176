/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Typography } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import HablemosEmailForm from './HablemosEmailForm';
import SocialMedia from './SocialMedia';
import NeoforniBreadcrumbs from '../shared/Components/NeoforniBreadcrumbs';
import { TEXT_DEFAULT_TO_SEND_WHATSAPP, GOOGLE_MAPS_SOURCE, INSTAGRAM_SOURCE, WHATSAPP_API, buildWhatsAppPreMessage } from '../shared/global';

const useStyles = makeStyles((theme) => ({
  instagram: {
    background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
    borderRadius: '20%',
    color: '#fafafa',
    fontSize: '20px',
    // boxShadow: '0px 3px 10px rgba(0,0,0,.25)',
  },
  whatsapp: {
    background: 'linear-gradient(#69ca3b, #56a61e)',
    borderRadius: '20%',
    fontSize: '20px',
    color: '#fafafa',
  },
}));

const ContactanosInformation = ({ title, infoOne, infoTwo }) => {
  const GridProps = {
    container: true,
    justify: 'center',
    alignItems: 'center',
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} {...GridProps}>
        <Typography variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} {...GridProps}>
        <Typography variant="body2" color="textSecondary">
          {infoOne}
        </Typography>
      </Grid>
      <Grid item xs={12} {...GridProps}>
        <Typography variant="body2" color="textSecondary">
          {infoTwo}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Contactanos = () => {
  const classes = useStyles();

  const handleAddUpdate = async (values) => {
    const text = buildWhatsAppPreMessage(values);
    const url = `${WHATSAPP_API}&text=${text}`;
    const newWindow = window.open(url, 'Whatsapp');
    newWindow?.focus();
  };

  return (
    <NeoforniContainer maxWidth="lg">
      <NeoforniBreadcrumbs />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <iframe
            src={GOOGLE_MAPS_SOURCE}
            width="100%"
            height="300"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContactanosInformation
            title="Teléfono de contacto"
            infoOne="+5491134702394"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContactanosInformation
            title="Showroom"
            infoOne="Si querés visitarnos contactanos al WhatsApp"
            infoTwo="para coordinar horarios"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContactanosInformation
            title="Seguinos en las redes sociales"
            infoOne={
              <SocialMedia
                name="WhatsApp"
                Icon={<WhatsappIcon classes={{ root: classes.whatsapp }} />}
                url={TEXT_DEFAULT_TO_SEND_WHATSAPP}
                urlName="Whatsapp"
              />
            }
            infoTwo={
              <SocialMedia
                name="@neo.forni"
                Icon={<InstagramIcon classes={{ root: classes.instagram }} />}
                url={INSTAGRAM_SOURCE}
                urlName="Instagram"
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <NeoforniContainer maxWidth="md" center>
            <Grid container spacing={4}>
              <Grid item container xs={12} justify="center">
                <Typography variant="h5">
                  Hablemos...
                </Typography>
              </Grid>
              <Grid item container xs={12} justify="center">
                <Typography variant="h6" color="secondary">
                  Si tenés dudas o querés visitar nuestro showroom escribinos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <HablemosEmailForm handleAddUpdate={handleAddUpdate} />
              </Grid>
            </Grid>
          </NeoforniContainer>
        </Grid>
      </Grid>
    </NeoforniContainer>
  );
};

ContactanosInformation.propTypes = {
  title: PropTypes.string,
  infoOne: PropTypes.any,
  infoTwo: PropTypes.any,
};

export default Contactanos;
