import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import StyleFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { devconsole } from '../tools';
import { FirebaseAdminContext } from './FirebaseAdminContext';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true,
      },
    },
  ],
};

const signOut = (setAdminLogged) => {
  setAdminLogged(null);
  firebase
    .auth()
    .signOut()
    .then(function () {
      devconsole.log('Successufully Signed out');
    })
    .catch(function () {
      devconsole.log('Error Signed out');
    });
};

const SignIn = () => {
  const [adminLogged, setAdminLogged] = useContext(FirebaseAdminContext);

  useEffect(() => {
    const authObserver = firebase.auth().onAuthStateChanged((user) => {
      devconsole.log(`changing logged admin ${user?.email}`);
      setAdminLogged(user);
    });
    return authObserver;
  });

  if (adminLogged) {
    return (
      <Grid container spacing={2}>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Grid item xs={12}>
            <Typography variant="caption">
              Usuario logueado: <b>{adminLogged.email}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Fecha de creación: <b>{adminLogged.metadata.creationTime}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Fecha del último inicio de sesión:{' '}
              <b>{adminLogged.metadata.lastSignInTime}</b>
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            xs={12}
          >
            <Typography variant="caption">
              <Box display="flex" alignItems="center">
                Email verificado:{' '}
                <Box ml={1}>
                  {adminLogged.emailVerified ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </Box>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Button
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            onClick={() => signOut(setAdminLogged)}
          >
            Cerrar sesión
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <StyleFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    );
  }
};

export default SignIn;
