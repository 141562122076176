import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormGridItem from './FormGridItem';

const FormTitle = ({ title, gridProps, ...props }) => {
  return (
    <FormGridItem {...gridProps}>
      <Typography variant="subtitle1" {...props}>{title}</Typography>
    </FormGridItem>
  );
};

FormTitle.propTypes = {
  title: PropTypes.node.isRequired,
  gridProps: PropTypes.object,
};

export default FormTitle;
