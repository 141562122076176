import { FormHelperText, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import FormGridItem, { FormGridItemPropTypes } from './FormGridItem';

const FieldGeneric = ({ name, children, gridProps }) => {
  const form = useFormContext();
  const controller = useController({
    name,
    control: form.control,
  });
  const error = form.errors[name]?.message;
  const renderProps = {
    form, controller, error,
  };
  const isFunc = typeof children === 'function';
  return (
    <FormGridItem {...gridProps}>
      {isFunc ? children(renderProps) : children}
    </FormGridItem>
  );
};

const FieldGenericLabelAndHelperText = ({
  name,
  children,
  gridProps,
  label,
  helperText,
  ...props
}) => {
  const form = useFormContext();
  const isFunc = typeof children === 'function';
  return (
    <FieldGeneric name={name} gridProps={gridProps} {...props}>
      {({ controller, error: errorField }) => {
        const error = errorField || helperText;
        return (
          <>
            <FormLabel htmlFor={name} error={error} style={{ fontSize: '12px' }} color="secondary">
              {label}
            </FormLabel>
            {isFunc ? children({ form, controller, error }) : children}
            {error && (
              <FormHelperText error>
                {error}
              </FormHelperText>
            )}
          </>
        );
      }}
    </FieldGeneric>
  );
};

const FieldGenericPropTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
  ...FormGridItemPropTypes,
};

const FieldGenericLabelAndHelperTextPropTypes = {
  ...FieldGenericPropTypes,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

FieldGeneric.propTypes = FieldGenericPropTypes;

FieldGenericLabelAndHelperText.propTypes = FieldGenericLabelAndHelperTextPropTypes;

export default FieldGeneric;
export { FieldGenericLabelAndHelperText, FieldGenericLabelAndHelperTextPropTypes };
