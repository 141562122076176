import { addItem, updateItem } from '../../firebase';

const updateReceta = async (item, id) => {
  return updateItem(item, id, 'recetas');
};

const addReceta = async (item) => {
  return addItem(item, 'recetas');
};

export {
  updateReceta,
  addReceta,
};
