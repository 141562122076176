import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  socialMedia: {
    cursor: 'pointer',
    '& svg': {
      marginLeft: '10px',
    },
    transition: 'color 0.5s ease',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const SocialMedia = ({ name, url, urlName, Icon }) => {
  const classes = useStyles();

  const handleClickSocialMedia = () => {
    const newWindow = window.open(url, urlName);
    newWindow?.focus();
  };

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-around"
      onClick={handleClickSocialMedia}
      className={classes.socialMedia}
    >
      {name} {Icon}
    </Box>
  );
};

SocialMedia.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  urlName: PropTypes.string,
  Icon: PropTypes.element,
};

export default SocialMedia;
