import PropTypes from 'prop-types';
import { getCollectionData, updateItem } from '../firebase';
import EditableZone from '../shared/Components/Editable';
import { usePromiseToState } from '../tools';
import NosotrosForm from './NosotrosForm';

const UpdateNosotrosPropTypes = {
  children: PropTypes.node.isRequired,
  nosotros: PropTypes.object.isRequired,
  reloadPage: PropTypes.func.isRequired,
};

const UpdateNosotros = ({ children, nosotros, reloadPage }) => {
  const handleUpdate = async (apiFormValues) => {
    try {
      const response = await updateItem(apiFormValues, nosotros.fid, 'nosotros');
      reloadPage();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    };
  };

  return (
    <EditableZone
      Editable={
        <NosotrosForm
          nosotros={nosotros}
          handleUpdate={handleUpdate}
        />
      }
    >
      {children}
    </EditableZone>
  );
};

UpdateNosotros.propTypes = UpdateNosotrosPropTypes;

export default UpdateNosotros;
