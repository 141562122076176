import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import Layout from '../Layout';
import { NAVIGATION_TITLE_DEFAULT } from '../shared/global';
import { FirebaseAdminContext } from '../Admin/FirebaseAdminContext';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';

const NotAuthorizedPath = () => {
  const history = useHistory();
  return (
    <NeoforniContainer center maxWidth="sm">
      <Alert
        variant="outlined"
        severity="error"
        action={(
          <Button variant="outlined" onClick={() => history.push('/admin')}>
            Iniciar sesión
          </Button>
        )}
      >
        No estás autorizado para ver esta pantalla
      </Alert>
    </NeoforniContainer>
  );
};

const CustomRoute = ({
  children,
  title,
  LayoutProps,
  adminRoute = false,
  ...props
}) => {
  const [adminLogged] = useContext(FirebaseAdminContext);
  // changes <title> tag for improving accesibility and navigation
  useEffect(() => {
    if (title) {
      document.title = `${title} - ${NAVIGATION_TITLE_DEFAULT}`;
    }
    return () => {
      document.title = NAVIGATION_TITLE_DEFAULT;
    };
  }, [title]);
  return (
    <Route {...props}>
      <Layout {...LayoutProps}>
        {adminRoute ? (adminLogged ? children : <NotAuthorizedPath />) : children}
      </Layout>
    </Route>
  );
};

CustomRoute.propTypes = {
  children: PropTypes.element,
  LayoutProps: PropTypes.object,
  title: PropTypes.string,
  adminRoute: PropTypes.bool,
};

export default CustomRoute;
