import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const FormGridContainer = ({ children, ...props }) => {
  return (
    <Grid container spacing={2} {...props}>
      {children}
    </Grid>
  );
};

const IFormGridContainerPropTypes = {
  children: PropTypes.node.isRequired,
};
const { children, ...FormGridItemPropTypes } = IFormGridContainerPropTypes;

FormGridContainer.propTypes = IFormGridContainerPropTypes;

export default FormGridContainer;
export { FormGridItemPropTypes };
