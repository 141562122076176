import { addItem, updateItem } from '../firebase';

const addTip = (item) => {
  return addItem(item, 'tips');
};

const updateTip = async (item, id) => {
  return updateItem(item, id, 'tips');
};

export {
  addTip,
  updateTip,
};
