/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FieldGeneric, {
  FieldGenericLabelAndHelperTextPropTypes,
} from './FieldGeneric';

const FieldSelect = ({
  name,
  label,
  options = [],
  required,
  gridProps = {},
  emptyLabel,
  onChange,
  sortByLabel,
  ...props
}) => {
  if (sortByLabel) {
    options.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;

      return 0;
    });
  }

  const handleChangeSelect = (e, controller) => {
    controller.field.onChange(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <FieldGeneric
      name={name}
      gridProps={gridProps}
    >
      {({ controller, error }) => (
        <>
          <label htmlFor={name}>{label}</label>
          <select
            value={controller.field.value}
            onChange={(e) => handleChangeSelect(e, controller)}
            style={{ width: '100%', height: '50px', padding: '10px', marginTop: '5px' }}
            {...props}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
          {error && (
            <FormHelperText error>
              {error}
            </FormHelperText>
          )}
        </>
      )}
    </FieldGeneric>
  );
};

const SelectProps = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  emptyLabel: PropTypes.any,
  sortByLabel: PropTypes.bool,
};

FieldSelect.propTypes = {
  ...SelectProps,
  ...FieldGenericLabelAndHelperTextPropTypes,
};

export default FieldSelect;
