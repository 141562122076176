import React from 'react';
import PropTypes from 'prop-types';
import { updateProduct } from './productosHelper';
import EditableSection from '../shared/Components/Editable';
import ProductosForm from './ProductosForm';

const ProductGenericPropTypes = {
  product: PropTypes.object.isRequired,
  reloadPage: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const ProductGeneric = ({ children, product, reloadPage }) => {
  const handleUpdateProduct = async (apiFormValues) => {
    try {
      const response = await updateProduct(apiFormValues, product.fid);
      reloadPage();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <EditableSection Editable={<ProductosForm handleAddUpdate={handleUpdateProduct} product={product} />}>
      {children}
    </EditableSection>
  );
};

ProductGeneric.propTypes = ProductGenericPropTypes;

export default ProductGeneric;
