import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ShowMarkUpText from '../shared/Components/ShowMarkUpText';

const NosotrosTitleDescriptionPropTypes = {
  nosotros: PropTypes.object.isRequired,
  reloadPage: PropTypes.func.isRequired,
};

const NosotrosTitleDescription = ({ nosotros }) => {
  const Icons = [ExtensionOutlinedIcon, VerifiedUserOutlinedIcon, FavoriteBorderIcon];
  const { title = '', description = '', image = '', product_offers: productOffers = [] } = nosotros || {};

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justify="center"
      spacing={4}
    >
      <Grid item container xs={12} alignItems="center" justify="center">
        <Typography role="title" variant="h5">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          role="description"
        >
          <ShowMarkUpText text={description} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img
          src={image}
          width="100%"
        />
      </Grid>
      <Grid item container spacing={3}>
        {productOffers.map(({ title, offer }, idx) => (
          <Grid key={title} item xs={12} sm={4}>
            <ProductOffer
              key={title}
              idx={idx}
              GridProps={{ item: true, xs: 4 }}
              title={title}
              description={offer}
              Icon={Icons[idx]}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const ProductOfferPropTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.any,
};

const ProductOffer = ({ title, description, Icon }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Box mb={2}><Icon /></Box>
      <Box mb={2}>{title}</Box>
      <Box
        overflow="hidden"
        textAlign="center"
        width="70%"
      >
        <Typography gutterBottom variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

ProductOffer.propTypes = ProductOfferPropTypes;
NosotrosTitleDescription.propTypes = NosotrosTitleDescriptionPropTypes;

export default NosotrosTitleDescription;
