import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  linearProgressRoot: {
    position: 'absolute',
    top: '48px',
    width: '100%',
  },
}));

const LinearProgressPortal = () => {
  const classes = useStyles();
  const rootNode = document.getElementById('root');
  return ReactDOM.createPortal(
    <LinearProgress
      classes={{ root: classes.linearProgressRoot }}
      color="primary"
    />,
    rootNode,
  );
};

const CircularProgressBox = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="500px"
    >
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

const LoadingErrorWrapper = ({
  data,
  loading,
  error,
  children,
  spinner,
  loadingSchema,
}) => {
  if (data === undefined || error === undefined || loading === undefined) return null;
  if (loading) {
    return (
      (spinner && <CircularProgressBox />) ||
      loadingSchema || <LinearProgressPortal />
    );
  } else if (error) return <Alert severity="error">{error}</Alert>;

  return children;
};

LoadingErrorWrapper.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.node,
  spinner: PropTypes.bool,
  loadingSchema: PropTypes.node,
};

export default LoadingErrorWrapper;
