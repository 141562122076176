import React from 'react';
import { useParams } from 'react-router';
import { usePromiseToState } from '../../tools';
import { getItem } from '../../firebase';
import LoadingErrorWrapper from '../../shared/Containers/LoadingErrorWrapper';
import NeoforniContainer from '../../shared/Containers/NeoforniContainer';
import ViewReceta from '../ViewReceta';
import UpdateReceta from '../UpdateReceta';

const Receta = () => {
  const { id } = useParams();
  const [recetaState,, reloadPage] = usePromiseToState({
    promise: () => getItem(id, 'recetas'),
  });

  const [receta] = recetaState.data;

  return (
    <NeoforniContainer maxWidth="md">
      <LoadingErrorWrapper spinner {...recetaState}>
        <UpdateReceta receta={receta} reloadPage={reloadPage}>
          <ViewReceta receta={receta} />
        </UpdateReceta>
      </LoadingErrorWrapper>
    </NeoforniContainer>
  );
};

Receta.propTypes = {
};

export default Receta;
