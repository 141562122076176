import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

const useStyles = props => makeStyles((theme) => ({
  box: {
    margin: '0 2px',
    border: '1px solid #ddd',
    padding: '10px 20px',
    borderRadius: '2px',
    transition: 'color 0.3s ease',
    backgroundColor: props.collapse ? '#eee' : '#fff',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const ViewTip = ({ title, description }) => {
  const [collapse, setCollapse] = useState(true);
  const classes = useStyles({ collapse })();

  if (!title || !description) return null;

  const handleClickCollapseBar = (e) => {
    setCollapse((prev) => !prev);
  };

  return (
    <Box
      className={classes.box}
      onClick={handleClickCollapseBar}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid
            item
            container
            xs={12}
          >
            <Grid item container xs={8} justify="flex-start" alignItems="center">
              <Typography variant="h6" color={collapse ? 'initial' : 'secondary'}>
                {title}
              </Typography>
            </Grid>
            <Grid item container xs={4} justify="flex-end" alignItems="center">
              <IconButton color="secondary">
                {collapse ? <ChevronRightIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!collapse} data-testid="collapsable">
              <Box mt={2}>
                <Typography variant="body1" color="textSecondary" paragraph>
                  {description}
                </Typography>
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ViewTip.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ViewTip;
