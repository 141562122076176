import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import FormGridItem from './FormGridItem';

const FieldArrayPairButtons = ({
  buttonAddProps,
  buttonRemoveProps,
  ...props
}) => {
  return (
    <FormGridItem container xs={2} alignItems="center" justify="center" {...props}>
      <IconButton color="primary" size="small" {...buttonAddProps}>
        <AddCircleIcon />
      </IconButton>
      <IconButton color="primary" size="small" {...buttonRemoveProps}>
        <RemoveCircleIcon />
      </IconButton>
    </FormGridItem>
  );
};

FieldArrayPairButtons.propTypes = {
  buttonAddProps: PropTypes.object,
  buttonRemoveProps: PropTypes.object,
};

export default FieldArrayPairButtons;
