import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormGridItem, { FormGridItemPropTypes } from './FormGridItem';
import { CircularProgress } from '@material-ui/core';

const FormSubmitButton = ({
  label = 'Guardar',
  gridProps,
  isSubmitting,
  ...props
}) => {
  return (
    <FormGridItem container justify="center" alignItems="center" {...gridProps}>
      <Button
        variant="contained"
        type="submit"
        disableElevation
        color={isSubmitting ? 'default' : 'secondary'}
        {...props}
      >
        {isSubmitting ? (
          <CircularProgress color="secondary" size={20} />
        ) : (
          label
        )}
      </Button>
    </FormGridItem>
  );
};

FormSubmitButton.propTypes = {
  label: PropTypes.string,
  isSubmitting: PropTypes.bool,
  ...FormGridItemPropTypes,
};

export default FormSubmitButton;
