import EditableSection from '../shared/Components/Editable';
import PropTypes from 'prop-types';
import CursoTitleDescriptionForm from './CursoTitleDescriptionForm';
import { updateTallerCurso } from './talleresCursosHelper';

const UpdateTalleresCursosPropTypes = {
  children: PropTypes.node.isRequired,
  tallerCurso: PropTypes.object.isRequired,
  reloadPage: PropTypes.func.isRequired,
};

const UpdateTallerCurso = ({ children, tallerCurso, reloadPage }) => {
  const handleUpdate = async (formValues) => {
    try {
      const response = await updateTallerCurso(formValues, tallerCurso.fid);
      reloadPage();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <EditableSection
      Editable={(
        <CursoTitleDescriptionForm
          tallerCurso={tallerCurso}
          handleAddUpdate={handleUpdate}
          reloadPage={reloadPage}
        />
      )}
    >
      {children}
    </EditableSection>
  );
};

UpdateTallerCurso.propTypes = UpdateTalleresCursosPropTypes;

export default UpdateTallerCurso;
