import { getCollectionData } from '../firebase';
import { usePromiseToState } from '../tools';
import LoadingErrorWrapper from '../shared/Containers/LoadingErrorWrapper';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import ViewTalleresCursos from './ViewTalleresCursos';
import AddTallerCurso from './AddTallerCurso';

const TalleresCursos = () => {
  const [talleresCursosState,, reloadPage] = usePromiseToState({
    promise: () => getCollectionData('cursos'),
  });

  return (
    <NeoforniContainer maxWidth="md">
      <AddTallerCurso reloadPage={reloadPage} />
      <LoadingErrorWrapper {...talleresCursosState} spinner>
        <ViewTalleresCursos talleresCursos={talleresCursosState?.data} reloadPage={reloadPage} />
      </LoadingErrorWrapper>
    </NeoforniContainer>
  );
};

export default TalleresCursos;
