import React from 'react';
import NeoforniContainer from '../shared/Containers/NeoforniContainer';
import SignInForm from './SignInForm';

const Admin = () => {
  return (
    <NeoforniContainer maxWidth="sm" center>
      <SignInForm />
    </NeoforniContainer>
  );
};

export default Admin;
